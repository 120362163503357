import { MonitorType } from '@enums';
import {
  Alert,
  InsertEmailRecipientsDTO,
  Monitor,
  Recipient,
  RecipientGroup,
  RecipientGroupType,
} from '@interfaces';
import { createAction, props } from '@ngrx/store';

export const loadBom = createAction('[Alerting/API] Load Monitors');

export const fetchMonitors = createAction(
  '[Alerting/API] Fetch All Monitors',
  props<{ monitorType?: MonitorType }>(),
);

export const fetchMonitorsSuccess = createAction(
  '[Alerting/API] Fetch All Monitors Success',
  props<{ result: Monitor[] }>(),
);

export const fetchMonitorById = createAction(
  '[Alerting/API] Fetch Monitor By Id',
  props<{ id: string }>(),
);

export const fetchMonitorByIdSuccess = createAction(
  '[Alerting/API] Fetch Monitor By Id Success',
  props<{ result: Monitor }>(),
);

export const upsertMonitor = createAction(
  '[Alerting/API] Upsert Monitor',
  props<{ monitor: Monitor }>(),
);

export const upsertMonitorSuccess = createAction(
  '[Alerting/API] Upsert Monitor Success',
  props<{ monitorType?: MonitorType }>(),
);

export const deleteMonitor = createAction(
  '[Alerting/API] Delete Monitor',
  props<{ monitorId: string }>(),
);

export const deleteMonitorSuccess = createAction(
  '[Alerting/API] Delete Monitor Success',
);

export const executeMonitor = createAction(
  '[Alerting/API] Execute Monitor',
  props<{ monitorId: string }>(),
);

export const executeMonitorSuccess = createAction(
  '[Alerting/API] Execute Monitor Success',
);

export const fetchReceiversGroup = createAction(
  '[Alerting/API] Fetch Recipient Groups',
);

export const fetchRecipientGroupsSuccess = createAction(
  '[Alerting/API] Fetch Recipient Groups Success',
  props<{ result: RecipientGroup[] }>(),
);

export const toggleRecipientGroupLoading = createAction(
  '[Alerting/API] Toggle Recipient Groups Loading',
  props<{ loading: boolean }>(),
);

export const fetchApproversGroups = createAction(
  '[Alerting/API] Fetch Approver Groups',
);

export const toggleApproverGroupLoading = createAction(
  '[Alerting/API] Toggle Approver Groups Loading',
  props<{ loading: boolean }>(),
);

export const fetchApproversGroupsSuccess = createAction(
  '[Alerting/API] Fetch Approver Groups Success',
  props<{ result: RecipientGroup[] }>(),
);

export const fetchReceiversFromGroup = createAction(
  '[Alerting/API] Fetch Recipients From Group',
  props<{ id: string }>(),
);

export const fetchReceiversFromGroupSuccess = createAction(
  '[Alerting/API] Fetch Recipients From Group Success',
  props<{ result: Recipient[] }>(),
);

export const fetchApproversFromGroup = createAction(
  '[Alerting/API] Fetch Approvers From Group',
  props<{ id: string }>(),
);

export const fetchApproversFromGroupSuccess = createAction(
  '[Alerting/API] Fetch Approvers From Group Success',
  props<{ result: Recipient[] }>(),
);

export const upsertRecipientEmails = createAction(
  '[Alerting/API] Insert Email Into Group',
  props<{ dto: InsertEmailRecipientsDTO }>(),
);

export const upsertRecipientEmailsSuccess = createAction(
  '[Alerting/API] Insert Email Into Group Success',
);

export const createNewReceiverGroup = createAction(
  '[Alerting/API] Create New Receiver Group',
  props<{ dto: RecipientGroup }>(),
);

export const createNewApproverGroup = createAction(
  '[Alerting/API] Create New Approver Group',
  props<{ dto: RecipientGroup }>(),
);

export const createNewReceiverGroupSuccess = createAction(
  '[Alerting/API] Create New Receiver Group Success',
  props<{ result: RecipientGroup[] }>(),
);

export const createNewApproverGroupSuccess = createAction(
  '[Alerting/API] Create New Approver Group Success',
  props<{ result: RecipientGroup[] }>(),
);

export const setSelectedReceiverGroup = createAction(
  'Set selected Receiver Group',
  props<{ id: string }>(),
);

export const setSelectedApproverGroup = createAction(
  'Set selected Approver Group',
  props<{ id: string }>(),
);

export const deleteRecipientGroup = createAction(
  '[Alerting/API] Delete Recipient Group',
  props<{ id: string; groupType: RecipientGroupType }>(),
);

export const deleteRecipientGroupSuccess = createAction(
  '[Alerting/API] Delete Recipient Group Success',
  props<{ groupType: RecipientGroupType }>(),
);

export const fetchAlerts = createAction('[Alerting/API] Fetch Alerts');

export const fetchAlertsSuccess = createAction(
  '[Alerting/API] Fetch Alerts Success',
  props<{ result: Alert[] }>(),
);

export const fetchAlertById = createAction(
  '[Alerting/API] Fetch Alert by Id',
  props<{ id: string }>(),
);

export const toggleAlertLoading = createAction(
  '[Alerting/API] Toggle Alert Loading',
  props<{ loading: boolean }>(),
);

export const fetchAlertByIdSuccess = createAction(
  '[Alerting/API] Fetch Alert by Id Success',
  props<{ result: Alert | null }>(),
);

export const fetchAlertByIdFailure = createAction(
  '[Alerting/API] Fetch Alert by Id Failure',
  props<{ error: string }>(),
);

export const deleteAlertById = createAction(
  '[Alerting/API] Delete Alert By Id',
  props<{ id: string }>(),
);

export const deleteAlertByIdSuccess = createAction(
  '[Alerting/API] Delete Alert By Id Success',
  props<{ result: string }>(),
);

export const approveAlertById = createAction(
  '[Alerting/API] Approve Alert By Id',
  props<{ id: string }>(),
);

export const approveAlertByIdSuccess = createAction(
  '[Alerting/API] Approve Alert By Id Success',
  props<{ result: Alert }>(),
);

export const updateAlertDataById = createAction(
  '[Alerting/API] Update Alert Data By Id',
  props<{ id: string }>(),
);

export const updateAlertDataByIdSuccess = createAction(
  '[Alerting/API] Update Alert Data By Id Success',
  props<{ result: Alert }>(),
);

export const selectGroupToDelete = createAction(
  '[Alerting/API] Select Group To Delete',
  props<{ group: RecipientGroup }>(),
);

export const sendAlertById = createAction(
  '[Alerting/API] Send Alert By Id',
  props<{ id: string }>(),
);

export const sendAlertByIdSuccess = createAction(
  '[Alerting/API] Send Alert By Id Success',
  props<{ result: Alert }>(),
);
