import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import { ModalService } from '@core-app/services/modal.service';
import { SharedModule } from '@frontend-workspace/shared/src';

@Component({
  selector: 'frontend-workspace-delete-item-modal',
  standalone: true,
  imports: [SharedModule, CommonModule],
  templateUrl: './delete-item-modal.component.html',
  styleUrl: './delete-item-modal.component.css',
})
export class DeleteItemModalComponent {
  public readonly modalService = inject(ModalService);
}
