import { Component, DestroyRef, OnInit, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Router } from '@angular/router';
import {
  fetchSingleItemsByProductionOrder,
  showModal,
} from '@frontend-workspace/shared/src/lib/+state/app.actions';
import {
  selectSingleItemsByProductionOrder,
  selectSingleItemsByProductionOrderLoading,
  selectedPart,
} from '@frontend-workspace/shared/src/lib/+state/app.selectors';
import { PartsSummaryDTO } from '@interfaces';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

@Component({
  selector: 'fip-production-order-navigation',
  templateUrl: './production-order-navigation.component.html',
  styleUrls: ['./production-order-navigation.component.css'],
})
export class ProductionOrderNavigationComponent implements OnInit {
  private readonly _router = inject(Router);
  private readonly _destroyRef = inject(DestroyRef);
  private readonly _store = inject(Store);

  loading$ = this._store.select(selectSingleItemsByProductionOrderLoading());

  partId: string;
  productionOrderId = '';
  singleItems: string[];
  partsFromProductionOrder: Record<string, PartsSummaryDTO[]> = {};
  currentIndex = 0;
  singleItems$: Observable<PartsSummaryDTO[] | null>;

  ngOnInit() {
    this.fetchItems();
  }

  fetchItems() {
    this._store
      .select(selectedPart())
      .pipe(takeUntilDestroyed(this._destroyRef))
      .subscribe((partData) => {
        this.partId = partData.serialNumber;

        if (
          Object.keys(partData).length > 0 &&
          partData?.selectedProductionOrderId !== this.productionOrderId
        ) {
          this.productionOrderId = partData.selectedProductionOrderId;
          this.setIndex(this.productionOrderId);

          this._store.dispatch(
            fetchSingleItemsByProductionOrder({
              productionOrderId: this.productionOrderId,
            }),
          );
        }
      });
  }

  setIndex(productionOrderId: string) {
    this._store
      .select(selectSingleItemsByProductionOrder(productionOrderId))
      .pipe(takeUntilDestroyed(this._destroyRef))
      .subscribe((items) => {
        if (items) {
          this.partsFromProductionOrder[this.productionOrderId] = items;
          const index = items.findIndex(
            (item) => item.externalSingleItemId == this.partId,
          );

          this.currentIndex = index > -1 ? index : 0;
        }
      });
  }

  next() {
    if (!this.partsFromProductionOrder[this.productionOrderId]) {
      return;
    }
    if (
      this.currentIndex + 1 >=
      this.partsFromProductionOrder[this.productionOrderId].length
    ) {
      return;
    }
    this.currentIndex++;
    const newPart =
      this.partsFromProductionOrder[this.productionOrderId][this.currentIndex];
    this.navigateToPart(newPart);
  }

  previous() {
    if (!this.partsFromProductionOrder[this.productionOrderId]) {
      return;
    }
    if (this.currentIndex - 1 < 0) {
      return;
    }
    this.currentIndex--;
    const newPart =
      this.partsFromProductionOrder[this.productionOrderId][this.currentIndex];
    this.navigateToPart(newPart);
  }

  navigateToPart(part: PartsSummaryDTO) {
    this.partId = part.externalSingleItemId || part.singleItemId;
    this._router.navigateByUrl(`/parts/${part.singleItemId}`);
  }

  openPartsModal() {
    this._store.dispatch(
      showModal({
        modalKey: 'part-selector',
        data: {},
        width: '900px',
      }),
    );
  }
}
