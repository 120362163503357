import { Component, Input } from '@angular/core';

@Component({
  selector: 'fip-data-card',
  templateUrl: './data-card.component.html',
  styleUrls: ['./data-card.component.scss'],
})
export class DataCardComponent {
  @Input({ required: false }) title: string | undefined;
}
