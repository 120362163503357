import { CommonModule } from '@angular/common';
import { Component, DestroyRef, inject, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Router } from '@angular/router';
import { selectedOrder } from '@core-app/state/production';
import { SharedModule } from '@frontend-workspace/shared/src';
import { Order } from '@frontend-workspace/shared/src/lib/interfaces';
import { Store } from '@ngrx/store';
import { filter } from 'rxjs';

@Component({
  selector: 'frontend-workspace-order-information-modal',
  templateUrl: './order-information-modal.component.html',
  styleUrl: './order-information-modal.component.scss',
  standalone: true,
  imports: [CommonModule, FormsModule, ReactiveFormsModule, SharedModule],
})
export class OrderInformationModalComponent implements OnInit {
  selectedOrder?: Order | null;
  private readonly _store = inject(Store);
  private readonly _router = inject(Router);
  destroyRef = inject(DestroyRef);

  ngOnInit(): void {
    this._store
      .select(selectedOrder())
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        filter((order) => !!order),
      )
      .subscribe((order) => {
        this.selectedOrder = order;
      });
  }

  goToDeliveries() {
    if (this.selectedOrder) {
      const { articleId, deliveryNoteId } = this.selectedOrder;
      this._router.navigate([`/production/deliveries/`], {
        queryParams: { articleId, deliveryNoteId },
      });
    }
  }
}
