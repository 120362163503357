import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { GenericChartComponent } from '../generic-chart/generic-chart.component';
import { LineChartDataPoints, Spline } from '@interfaces';

@Component({
  selector: 'fip-bar-chart',
  templateUrl: './bar-chart.component.html',
  styleUrls: ['./bar-chart.component.scss'],
})
export class BarChartComponent
  extends GenericChartComponent
  implements OnInit, OnChanges
{
  @Input() dataPoints: Array<LineChartDataPoints | Spline> = [];
  @Input() ticksuffix = '';
  @Input() orientation: 'v' | 'h' = 'v';
  @Input() text: string[] = [];
  @Input() hoverinfo: 'text' | 'none' = 'text';

  override refreshGraph() {
    const x = this.dataPoints[0].x;
    const y = this.dataPoints[0].y;
    const trace = this.createTrace(x, y);

    this.data = [];
    this.configXAxis(x);

    this.data = [trace, ...this.dataPoints.slice(1)];
    this.layout.shapes = [...this.shapes];

    this.layout = Object.assign({}, this.layout, {
      width: this.width,
      margin: {
        t: 20,
        l: 150,
        r: 0,
      },
      font: {
        size: 12,
      },
      orientation: this.orientation,
      xaxis: {
        title: { text: this.xTitle },
        ticktext: this.xTicksLabels,
        tickvals: this.xTicksVals,
        autorange: true,
        fixedrange: true,
      },
      yaxis: {
        title: { text: this.yTitle },
        autorange: true,
        ticksuffix: this.ticksuffix,
        fixedrange: true,
      },
    });
    this.layout.xaxis.ticktext = this.xTicksLabels;
    this.layout.xaxis.tickvals = this.xTicksVals;
    this.configLegend();
  }

  configXAxis(x: number[]) {
    let minXValue = Math.min(...x);
    let maxXValue = Math.max(...x);

    this.dataPoints.forEach((shape) => {
      if ((shape as Spline).x) {
        minXValue = Math.min(...shape.x, minXValue);
        maxXValue = Math.max(...shape.x, maxXValue);
      }
    });

    this.shapes.forEach((shape) => {
      if (shape.x0 && shape.x1) {
        minXValue = Math.min(shape.x0, shape.x1, minXValue);
        maxXValue = Math.max(shape.x0, shape.x1, maxXValue);
      }
    });

    this.layout.xaxis.range = [minXValue, maxXValue];
    this.layout.xaxis.ticktext = x.map((val) => val.toString());
    this.layout.xaxis.tickvals = x;
  }

  createTrace(x: number[], y: number[]) {
    const trace = {
      name: '',
      x,
      y,
      marker: {
        color: 'rgba(255, 100, 102, 0.7)',
        line: {
          color: 'rgba(255, 100, 102, 1)',
          width: 1,
        },
      },
      type: 'bar',
      text: this.text,
      orientation: this.orientation,
      hoverinfo: this.hoverinfo,
    };

    return trace;
  }

  override ngOnChanges(changes: SimpleChanges) {
    if (changes['dataPoints']) {
      this.refreshGraph();
    }
  }
}
