<form [formGroup]="formGroup" (submit)="submit()">
  <fip-form class="mb-2 block">
    <mat-form-field class="w-full">
      <mat-label>Name</mat-label>
      <input matInput type="string" formControlName="name" />
    </mat-form-field>
  </fip-form>
  <fip-form class="mb-2 block">
    <mat-form-field class="w-full">
      <mat-label>Recipients Group</mat-label>

      <mat-select formControlName="recipientGroupId">
        <mat-option
          *ngFor="let group of recipientGroups$ | async"
          [value]="group.rowKey"
          >{{ group.name }}</mat-option
        >
      </mat-select>
    </mat-form-field>
  </fip-form>

  <fip-form class="mb-2 block">
    <mat-form-field class="w-full">
      <mat-label>Approvers Group</mat-label>

      <mat-select formControlName="approversGroupId">
        <mat-option
          *ngFor="let group of approverGroups$ | async"
          [value]="group.rowKey"
          >{{ group.name }}</mat-option
        >
      </mat-select>
    </mat-form-field>
  </fip-form>

  <fip-form class="mb-2 block">
    <mat-form-field
      class="w-full"
      [attr.disabled]="(companies$ | async)?.length === 0"
    >
      <mat-label>Company</mat-label>

      <mat-select data-cy="company-select" formControlName="companyId">
        <mat-option
          *ngFor="let company of companies$ | async"
          [value]="company.code"
          >{{ company.name }}</mat-option
        >
      </mat-select>
    </mat-form-field>
  </fip-form>

  <fip-form class="mb-2 block">
    <mat-form-field class="w-full">
      <mat-label>Initial Query Date</mat-label>
      <input
        matInput
        [matDatepicker]="initialQueryDate"
        formControlName="initialQueryDate"
      />
      <mat-hint>MM/DD/YYYY</mat-hint>
      <mat-datepicker-toggle
        matIconSuffix
        [for]="initialQueryDate"
      ></mat-datepicker-toggle>
      <mat-datepicker #initialQueryDate></mat-datepicker>
    </mat-form-field>
  </fip-form>

  <fip-form formType="submit" class="text-center">
    <fip-button
      (clickEvent)="submit()"
      [label]="submitButtonText"
      backgroundColor="black"
      textColor="white"
      classes="!h-14 mt-5"
    ></fip-button>
  </fip-form>
</form>
