import {
  Component,
  ComponentRef,
  Inject,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DialogData } from '@interfaces';

@Component({
  selector: 'frontend-workspace-custom-modal',
  templateUrl: './custom-modal.component.html',
  styleUrls: ['./custom-modal.component.scss'],
})
export class CustomModalComponent implements OnInit, OnDestroy {
  @ViewChild('target', { read: ViewContainerRef, static: true })
  vcRef!: ViewContainerRef;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  componentRef!: ComponentRef<any>;
  constructor(
    private dialogRef: MatDialogRef<DialogData>,
    private viewContainerRef: ViewContainerRef,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
  ) {}

  ngOnInit(): void {
    if (this.data.component) {
      this.componentRef = this.viewContainerRef.createComponent(
        this.data.component,
      );

      this.componentRef.instance.data = this.data.nestedData;
    }
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  ngOnDestroy() {
    if (this.componentRef) {
      this.componentRef.destroy();
    }
  }
}
