import { Company, PartsSummaryDTO, PortalNotification } from '@interfaces';
import { Action, createReducer, on } from '@ngrx/store';
import * as UIActions from './ui.actions';

export const UI_FEATURE_KEY = 'ui';

export interface SelectedPartData {
  serialNumber: string;
  selectedProductionOrderId: string;
}

export interface UIState {
  selectedPart: SelectedPartData;
  singleItemsByProductionOrder: { [key: string]: PartsSummaryDTO[] | null };
  singleItemsByProductionOrderLoading: boolean;
  notifications: null | PortalNotification[];
  allCompaniesRelatedToArticles: Company[] | null;
}

const initialUIState: UIState = {
  selectedPart: <SelectedPartData>{},
  singleItemsByProductionOrder: {},
  singleItemsByProductionOrderLoading: false,
  notifications: null,
  allCompaniesRelatedToArticles: null,
};

const reducer = createReducer(
  initialUIState,
  on(UIActions.selectPart, (state, { selectedPart }) => {
    return {
      ...state,
      selectedPart: selectedPart,
    };
  }),
  on(
    UIActions.fetchSingleItemsByProductionOrderSuccess,
    (state, { items, productionOrderId }) => {
      const singleItems = { ...state.singleItemsByProductionOrder };

      // Sort by externalSingleItemId alphabetically
      let newItems = [] as PartsSummaryDTO[];

      if (items) {
        newItems = [...items];

        newItems = newItems?.sort(
          (a, b) =>
            a.externalSingleItemId?.localeCompare(b.externalSingleItemId || ''),
        );
      }
      singleItems[productionOrderId] = newItems;

      return {
        ...state,
        singleItemsByProductionOrder: singleItems,
      };
    },
  ),
  on(
    UIActions.toggleSingleItemsByProductionOrderLoading,
    (state, { loading }) => {
      return {
        ...state,
        singleItemsByProductionOrderLoading: loading,
      };
    },
  ),

  on(UIActions.fetchNotificationsSuccess, (state, { result }) => ({
    ...state,
    notifications: result,
  })),

  on(UIActions.markNotificationAsRead, (state, { id }) => ({
    ...state,
    notifications: (state.notifications ?? []).map((notification) =>
      notification.rowKey === id
        ? { ...notification, isRead: true }
        : notification,
    ),
  })),

  on(
    UIActions.fetchAllCompaniesRelatedToArticlesSuccess,
    (state, { result }) => ({
      ...state,
      allCompaniesRelatedToArticles: result.filter((company) => company.name),
    }),
  ),
);

export function appReducer(state: UIState | undefined, action: Action) {
  return reducer(state, action);
}
