import { Injectable, inject } from '@angular/core';
import {
  ApiResponse,
  Company,
  DeliveryCore,
  DeliveryPosition,
  Order,
} from '@interfaces';
import { Observable } from 'rxjs';
import { ApiService } from './api.service';
import * as dayjs from 'dayjs';

@Injectable({
  providedIn: 'root',
})
export class LogisticsService {
  private readonly _apiPath = 'logistics';
  private readonly _api = inject(ApiService);

  getOrders(
    articleId: string | null | undefined,
    companyCode: string | null | undefined,
    fromDate: dayjs.Dayjs | null | undefined,
    toDate: dayjs.Dayjs | null | undefined,
  ): Observable<ApiResponse<Order[]>> {
    const params = new URLSearchParams();
    if (articleId) {
      params.append('articleId', articleId);
    }
    if (companyCode) {
      params.append('companyCode', companyCode);
    }
    if (fromDate) {
      params.append('from', dayjs(fromDate).format('YYYY/MM/DD'));
    }
    if (toDate) {
      params.append('to', dayjs(toDate).format('YYYY/MM/DD'));
    }

    return this._api.get<Order[]>(
      `${this._apiPath}/orders?${params.toString()}`,
    );
  }

  getDeliveriesCore(
    deliveryNoteId: string,
    companyCode: string,
  ): Observable<ApiResponse<DeliveryCore[]>> {
    const params = new URLSearchParams();
    if (deliveryNoteId && deliveryNoteId !== '') {
      params.append('deliveryNoteId', deliveryNoteId);
    }
    if (companyCode && companyCode !== '') {
      params.append('companyCode', companyCode);
    }
    return this._api.get<DeliveryCore[]>(
      `${this._apiPath}/deliveries/core?${params.toString()}`,
    );
  }

  getDeliveryPositions(
    deliveryNoteId: string,
    companyCode: string,
  ): Observable<ApiResponse<DeliveryPosition[]>> {
    const params = new URLSearchParams();
    if (deliveryNoteId && deliveryNoteId !== '') {
      params.append('deliveryNoteId', deliveryNoteId);
    }
    if (companyCode && companyCode !== '') {
      params.append('companyCode', companyCode);
    }
    return this._api.get<DeliveryPosition[]>(
      `${this._apiPath}/deliveries/position?${params.toString()}`,
    );
  }

  getCompanies(articleId?: string): Observable<ApiResponse<Company[]>> {
    const params = new URLSearchParams();
    if (articleId && articleId !== '') {
      params.append('articleId', articleId);
    }

    return this._api.get<Company[]>(
      `${this._apiPath}/companies?${params.toString()}`,
    );
  }
}
