<div class="flex flex-col items-center space-y-3 p-6">
  <h1>Are you sure you want to delete receipt group {{ name }}?</h1>
  <fip-button
    label="Delete"
    [classes]="'!bg-accent-red'"
    text-color="white"
    (clickEvent)="submit()"
  >
  </fip-button>
</div>
