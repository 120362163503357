<mat-dialog-content>
  <form [formGroup]="formGroup" (submit)="submit()">
    <fip-form class="flex-1">
      <mat-form-field class="w-full">
        <mat-label>Approvers Group</mat-label>

        <mat-select formControlName="approversGroupId">
          <mat-option
            *ngFor="let group of recipientGroups$ | async"
            [value]="group.rowKey"
            >{{ group.name }}</mat-option
          >
        </mat-select>
      </mat-form-field>
    </fip-form>
    <fip-form formType="submit">
      <fip-button
        (clickEvent)="submit()"
        [label]="'Save Configurations'"
        backgroundColor="black"
        textColor="white"
        classes="!h-14 mt-5"
      ></fip-button>
    </fip-form>
  </form>
</mat-dialog-content>
