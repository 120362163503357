import { createFeatureSelector, createSelector } from '@ngrx/store';
import { USER_FEATURE_KEY, UserState } from './user.reducer';

export const selectUserState =
  createFeatureSelector<UserState>(USER_FEATURE_KEY);

export const selectFeatures = createSelector(
  selectUserState,
  (state: UserState) => state.featureFlags,
);

export const selectUser = createSelector(
  selectUserState,
  (state: UserState) => state.user,
);
