import {
  Component,
  DestroyRef,
  ElementRef,
  inject,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { FeatureFlagsService } from '@core-app/services/feature-flags.service';
import * as SearchActions from '@core-app/state/search/search.actions';
import { SearchState } from '@core-app/state/search/search.reducer';
import {
  searchLoading,
  selectSavedSerialNumbers,
  selectSerialSearchResultsBySerialNumber,
} from '@core-app/state/search/search.selectors';
import { ModalKey } from '@frontend-workspace/shared/src/lib/types/modal-key';
import { SerialSearchResult } from '@interfaces';
import { Store } from '@ngrx/store';
import { tap } from 'rxjs';
// Import only needed items
import {
  BarcodeCapture,
  barcodeCaptureLoader,
  BarcodeCaptureOverlay,
  BarcodeCaptureOverlayStyle,
  BarcodeCaptureSettings,
  Symbology,
} from 'scandit-web-datacapture-barcode';
import {
  Anchor,
  Camera,
  CameraFOVSwitchControl,
  CameraSwitchControl,
  configure,
  DataCaptureContext,
  DataCaptureView,
  FrameSourceState,
  RectangularViewfinder,
  TorchSwitchControl,
} from 'scandit-web-datacapture-core';

@Component({
  selector: 'fip-part-search-modal',
  templateUrl: './part-search.modal.html',
  styleUrls: ['./part-search.modal.scss'],
})
export class PartSearchModalComponent implements OnInit, OnDestroy {
  readonly modalKey: ModalKey = 'part-search_part-search';
  private readonly _destroyRef = inject(DestroyRef);
  private readonly _store = inject(Store<SearchState>);
  private readonly _router = inject(Router);
  readonly featureFlagService = inject(FeatureFlagsService);

  savedSerialNumbers$ = this._store.select(selectSavedSerialNumbers);
  searchLoading$ = this._store.select(searchLoading(this.modalKey));
  searchResult?: SerialSearchResult;
  searchFormGroup = new FormGroup({
    partNumber: new FormControl('', [Validators.required]),
  });
  isSearchTriggered = false;
  isScanningActive = false;
  overlay: BarcodeCaptureOverlay;
  barcodeCapture: BarcodeCapture;
  context: DataCaptureContext;
  view: DataCaptureView;

  @ViewChild('videoContainer') videoElement: ElementRef<HTMLVideoElement>;

  ngOnInit() {
    // Disable form group while loading...
    this.searchLoading$
      .pipe(takeUntilDestroyed(this._destroyRef))
      .subscribe((loading) => {
        if (loading) {
          this.searchFormGroup.disable();
        } else {
          this.searchFormGroup.enable();
        }
      });
  }

  async initScanning() {
    this.view = new DataCaptureView();
    // Connect the data capture view to the HTML element.
    this.view.connectToElement(this.videoElement.nativeElement);

    this.view.logoAnchor = Anchor.TopRight;
    // Show the loading layer
    this.view.showProgressBar();

    // Enter your Scandit License key here.
    // Your Scandit License key is available via your Scandit SDK web account.
    // The passed parameter represents the location of the wasm file, which will be fetched asynchronously.
    // You must `await` the returned promise to be able to continue.
    await configure({
      licenseKey:
        'AheFZixZCfLKF5owsBpfeFkNWb7AJJD2RzRHobhD6BMlSfquLzJABD5R9wBjXuFAnitIwbswjzumSb1eiHUfcXVtXz/nY8oyt0j0/Uk9ux6iG9p+HyHRXT45wBG4L5wNfyRJLAZRqzzZWHmU/V/lUPdid/mnD8HZEwbOsaR1MqHTZBAU4HIZ3LJNUG0Re/F+8jEVtzkhKZ0AeUdpuGpRdc5MrN9oISi2lGkjJOESEUi2LXIbkzNW5tRVSahcaL6KhR1ldnsb/e+pJhGR8QEH108RTgkxcSd0QyAXhAY9oMdVFt0IpRoTY8o+ixaSKNR2FjgyDdo7TTkOLkhUgQTR6CwXfP7wP5HdtzJ2Arpq+uwYaon6pV/FnNgavYJ5ZBY99EqsMhdPPWUUU3DUujwb4UxGe7FFZDn9623K7jkfKRGwYuJ6bCbp8PRjH+lcXsLMSEkqBABGUDUteFVjnS8pqsdk9aGZA8rcZy+9pDcT15QRLFH8bHCQUtQfk2flODXYY21eYTZnnGoifk/NBiCCTyxDe9bFQj1MZ3E3Mf1cqF9MJ1kEFU6hD7Ffkk6LKK7/GTGVLZJGZiQJcMeThFZbIE0XXIqYU5KSDAfjOls6Lf0hVPc+TXFstZJJZF2HeyGvzXWr4DMaf2G3clcdNX3FtmVqrSXtcemQ+kvFfOcfL+UvTxBZ4Ea1gDct5Z0KCNzEwh0sN/sdf13vNlh3YmXub6R2mPbJXcMR/EYP49F9E6ntdwzrUAIMqTIzgngADDUz1KDg3uDeBy5Y3XZsgV01UZOXthZe0PCSHKkzQzYbTDPOWwzdsx1iMAd7HWHcGWNnWj0mA6+SYwpixZKmm3hvrYmRzIx7oygNFuDKqQBeCwubForkXts8thaA2l7BfTXMRCK5X7SQ5xhpNacHhl+XAfkdb1Y8zw8wLvNcGAiHiMpF5Yia6r1vcw7+klymfCMM9A0S/5aIxe7Hz22PGzDi2bSQ0+5Umqqt/+kvyGkhYN04/BuzTgr7eIcdAVyqE/K32p7SSWN0rO3ZkBa6mZ9NmDrT0giu9Z2HdKyJVh9ClkyY+5XRSaUUhRvRFG2c9/Kvgb207XpFtINJtWkEKeD1n25FyocQoYnL9wiTaztALH8B8ZCmSYVtyIDpwJnv+9HiwAADRDA+XF7MfW3YxJ80MI5lQt7kyFOQEOaXE390yr2E+8YzgzS09O1sW4TaLW2S6WnDFjdYw9EKqs8m1jz1+Bbt9K7/v86713m9bkGsKayPkIesYKZbeQ68yhKUlwcmm1ATi1XzD7ABdHLCToZ+DE5Vtq8TShp8iGtOmtGCQE1rZcJ6cJ3t0Lx+8vD5D4ctzM5WllF2lIE/HZ9eYG+m0NLAer9Bq77zrOpuey0C+J9wnqtvQ8ANBiDILLcr4ICLnptPzJDMQ7ug0wt64aa6cTt7sdjFz34YTt+7iQ==',
      libraryLocation:
        'https://cdn.jsdelivr.net/npm/scandit-web-datacapture-barcode@6.x/build/engine/',
      moduleLoaders: [barcodeCaptureLoader({ highEndBlurryRecognition: true })],
    });

    // Set the progress bar to be in an indeterminate state
    this.view.setProgressBarPercentage(null);
    this.view.setProgressBarMessage('Accessing Camera...');

    // Create the data capture context.
    this.context = await DataCaptureContext.create();

    // To visualize the ongoing barcode capturing process on screen, set up a data capture view that renders the
    // camera preview. The view must be connected to the data capture context.
    await this.view.setContext(this.context);

    // Try to use the world-facing (back) camera and set it as the frame source of the context. The camera is off by
    // default and must be turned on to start streaming frames to the data capture context for recognition.
    const camera: Camera = Camera.default;
    const cameraSettings = BarcodeCapture.recommendedCameraSettings;

    await camera.applySettings(cameraSettings);
    await this.context.setFrameSource(camera);

    // The barcode tracking process is configured through barcode tracking settings,
    // they are then applied to the barcode tracking instance that manages barcode recognition.
    const settings: BarcodeCaptureSettings = new BarcodeCaptureSettings();

    // Filter out duplicate barcodes for 1 second.
    settings.codeDuplicateFilter = 1000;

    // The settings instance initially has all types of barcodes (symbologies) disabled. For the purpose of this
    // sample we enable a very generous set of symbologies. In your own app ensure that you only enable the
    // symbologies that your app requires as every additional enabled symbology has an impact on processing times.
    settings.enableSymbologies([Symbology.DataMatrix]);

    const symbologySettings = settings.settingsForSymbology(
      Symbology.DataMatrix,
    );
    symbologySettings.isColorInvertedEnabled = true;

    // Create a new barcode tracking mode with the settings from above.
    this.barcodeCapture = await BarcodeCapture.forContext(
      this.context,
      settings,
    );

    // Disable the barcode tracking mode until the camera is accessed.
    await this.barcodeCapture.setEnabled(false);

    // Add a control to be able to switch cameras.
    this.view.addControl(new CameraSwitchControl());
    this.view.addControl(new TorchSwitchControl());
    this.view.addControl(new CameraFOVSwitchControl());

    this.overlay =
      await BarcodeCaptureOverlay.withBarcodeCaptureForViewWithStyle(
        this.barcodeCapture,
        this.view,
        BarcodeCaptureOverlayStyle.Frame,
      );
    const viewfinder = new RectangularViewfinder();
    await this.overlay.setViewfinder(viewfinder);
    await this.view.addOverlay(this.overlay);

    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const self = this;

    // Register a listener to get updates about tracked barcodes.
    this.barcodeCapture.addListener({
      didScan: async (barcodeCapture, session) => {
        const trackedBarcode = session.newlyRecognizedBarcode;

        if (trackedBarcode?.data) {
          self.scanSuccessHandler(trackedBarcode?.data);
          // Hide the viewfinder.
          await this.overlay.setViewfinder(null);
          barcodeCapture.setEnabled(false);

          this.context.frameSource?.switchToDesiredState(FrameSourceState.Off);
          this.view.detachFromElement();
        }
      },
    });

    // Switch the camera on to start streaming frames.
    // The camera is started asynchronously and will take some time to completely turn on.
    await this.context.frameSource?.switchToDesiredState(FrameSourceState.On);
    await this.barcodeCapture.setEnabled(true);
    this.view.hideProgressBar();
  }

  submit(serialNumber?: string) {
    if (serialNumber) {
      this.searchFormGroup.get('partNumber')?.setValue(serialNumber);
    }

    const param = serialNumber ?? this.searchFormGroup.value.partNumber;
    if (!param || !this.searchFormGroup.valid) {
      return;
    }

    const partNumber = this.searchFormGroup.value.partNumber;

    if (partNumber) {
      this.isSearchTriggered = true;
      // Listen to search result from ngrx store
      this._store
        .select(selectSerialSearchResultsBySerialNumber(partNumber))
        .pipe(
          takeUntilDestroyed(this._destroyRef),
          tap((data) => {
            if (data) {
              this._store.dispatch(
                SearchActions.reorderSearchResults({
                  searchType: 'serialNumbers',
                  payload: partNumber,
                }),
              );
            } else {
              this.searchResult = undefined;
            }
          }),
        )
        .subscribe((data) => (this.searchResult = data));

      this._store.dispatch(
        SearchActions.loadPartBySerialNumber({
          serialNumber: param,
          save: true,
        }),
      );
    }
  }

  navigateToSummary() {
    this._router.navigate(['parts', this.searchResult?.singleItemId]);
  }

  startScanning() {
    this.isScanningActive = true;

    this.initScanning();
  }

  scanSuccessHandler(result: string | null) {
    this.isScanningActive = false;

    if (result) {
      this.submit(result);
    }
  }

  ngOnDestroy() {
    // Clear search result before closing modal
    this.searchResult = undefined;
    // Remove loading before closing modal
    this._store.dispatch(
      SearchActions.toggleSearchLoading({
        searchType: this.modalKey,
        loading: false,
      }),
    );

    this.cleanupScanner();
  }

  async cleanupScanner() {
    await this.overlay?.setViewfinder(null);

    this.barcodeCapture?.setEnabled(false);
    this.context?.frameSource?.switchToDesiredState(FrameSourceState.Off);
    this.view?.detachFromElement();
  }
}
