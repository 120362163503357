import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ALERTING_FEATURE_KEY, AlertingState } from './alerting.reducer';

export const selectAlerting =
  createFeatureSelector<AlertingState>(ALERTING_FEATURE_KEY);

export const selectMonitors = () =>
  createSelector(selectAlerting, (state: AlertingState) => state.monitors);

export const selectSelectedMonitor = () =>
  createSelector(
    selectAlerting,
    (state: AlertingState) => state.selectedMonitor,
  );

export const selectReceiversGroups = () =>
  createSelector(
    selectAlerting,
    (state: AlertingState) => state.receiverGroups,
  );

export const selectApproversGroups = () =>
  createSelector(
    selectAlerting,
    (state: AlertingState) => state.approverGroups,
  );

export const selectedReceiverRecipientGroup = () =>
  createSelector(
    selectAlerting,
    (state: AlertingState) => state.selectedReceiverGroup,
  );

export const selectedApproverRecipientGroup = () =>
  createSelector(
    selectAlerting,
    (state: AlertingState) => state.selectedApproverGroup,
  );

export const selectReceivers = () =>
  createSelector(selectAlerting, (state: AlertingState) => state.receivers);

export const selectApprovers = () =>
  createSelector(selectAlerting, (state: AlertingState) => state.approvers);

export const selectAlerts = () =>
  createSelector(selectAlerting, (state: AlertingState) => state.alerts);

export const selectNotifications = () =>
  createSelector(selectAlerting, (state: AlertingState) => state.notifications);

export const selectSelectedAlert = () =>
  createSelector(selectAlerting, (state: AlertingState) => state.selectedAlert);

export const selectRecipientGroupToDelete = () =>
  createSelector(
    selectAlerting,
    (state: AlertingState) => state.recipientGroupToDelete,
  );

export const selectAllCompanies = () =>
  createSelector(selectAlerting, (state: AlertingState) => state.allCompanies);

export const selectAlertingLoading = () =>
  createSelector(selectAlerting, (state: AlertingState) => state.loading);
