<div>
  <div
    class="relative p-8"
    *ngIf="singleItemsLoading$ | async; else singleItemsLoaded"
  >
    <fip-loading-spinner class="left-2/4 top-2/4"></fip-loading-spinner>
  </div>
  <ng-template #singleItemsLoaded>
    <fip-table
      [columns]="cols"
      [displayedColumns]="colsDisplay"
      [data]="(singleItems$ | async) ?? []"
      [paginated]="true"
    ></fip-table>
  </ng-template>
</div>
