import { Injectable, inject } from '@angular/core';

import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ApiEndpointType } from '@frontend-workspace/shared/src/lib/services/api.service';
import {
  Alert,
  Company,
  InsertEmailRecipientsDTO,
  Monitor,
  PortalNotification,
  Recipient,
  RecipientGroup,
  RecipientGroupType,
} from '@interfaces';
import { ApiService } from './api.service';
import { MonitorType } from '@enums';

@Injectable({
  providedIn: 'root',
})
export class AlertingService {
  private readonly _api = inject(ApiService);

  getMonitorsByType(monitorType?: MonitorType) {
    return this._api.get<Monitor[]>(
      `monitors/type/${monitorType}`,
      {},
      ApiEndpointType.Function,
    );
  }

  getMonitorById(id: string) {
    return this._api.get<Monitor>(
      `monitors/${id}`,
      {},
      ApiEndpointType.Function,
    );
  }

  upsertMonitors(baseMonitor: Monitor) {
    const monitor: Monitor = {
      ...baseMonitor,
      partitionKey: 'v1',
    };

    return this._api.post(`monitors`, monitor, ApiEndpointType.Function);
  }

  deleteMonitor(id: string) {
    return this._api.delete(`monitors/${id}`, {}, ApiEndpointType.Function);
  }

  executeMonitor(id: string) {
    return this._api.post(
      `execute-monitors/id/`,
      { rowKey: id },
      ApiEndpointType.Function,
    );
  }

  getRecipientGroups(groupType: RecipientGroupType) {
    return this._api.get<RecipientGroup[]>(
      `recipient-groups/${groupType}`,
      {},
      ApiEndpointType.Function,
    );
  }

  getRecipientsFromGroup(groupId: string) {
    return this._api.get<Recipient[]>(
      `recipients/${groupId}`,
      {},
      ApiEndpointType.Function,
    );
  }

  upsertEmailRecipients(dto: InsertEmailRecipientsDTO) {
    return this._api.post(`recipients/`, dto, ApiEndpointType.Function);
  }

  createNewRecipientGroup(dto: RecipientGroup) {
    return this._api.post<RecipientGroup[], RecipientGroup>(
      `recipient-groups/`,
      dto,
      ApiEndpointType.Function,
    );
  }

  deleteRecipientGroup(groupId: string) {
    return this._api.delete<RecipientGroup[]>(
      `recipient-groups/${groupId}`,
      {},
      ApiEndpointType.Function,
    );
  }

  getNotifications() {
    return this._api.get<PortalNotification[]>(
      `notifications`,
      { params: {} },
      ApiEndpointType.Function,
    );
  }

  getAlerts() {
    return this._api.get<Alert[]>(`alerts`, {}, ApiEndpointType.Function);
  }

  getAlertById(id: string) {
    return this._api.get<Alert>(`alerts/${id}`, {}, ApiEndpointType.Function);
  }

  updateAlertDataById(id: string) {
    return this._api.put<Alert, unknown>(
      `alerts/${id}/data`,
      {},
      ApiEndpointType.Function,
    );
  }

  approveAlertById(id: string) {
    return this._api.put<Alert, unknown>(
      `alerts/${id}/state/ready`,
      {},
      ApiEndpointType.Function,
    );
  }

  deleteAlertById(id: string) {
    return this._api.delete<string>(
      `alerts/${id}`,
      {},
      ApiEndpointType.Function,
    );
  }

  getAllCompanies() {
    return this._api.get<Company[]>(`companies`, {}, ApiEndpointType.Function);
  }

  markNotificationAsRead(id: string) {
    return this._api.put<PortalNotification, unknown>(
      `notifications/read/${id}`,
      {},
      ApiEndpointType.Function,
    );
  }
}

export function createNewReductionInShipmentSizeFormGroup(): FormGroup {
  return new FormGroup({
    name: new FormControl('', [Validators.required]),
    recipientGroupId: new FormControl('', [Validators.required]),
    shipmentId: new FormControl('', [Validators.required]),
    edi: new FormControl(0, [Validators.required]),
  });
}

export function createNewDailyProductionTargetMissedFormGroup(): FormGroup {
  return new FormGroup({
    name: new FormControl('', [Validators.required]),
    recipientGroupId: new FormControl('', [Validators.required]),
    productionId: new FormControl('', [Validators.required]),
    productionTarget: new FormControl(0, [Validators.required]),
  });
}

export function createNewFailureDuringIncomingInspectionFormGroup(): FormGroup {
  return new FormGroup({
    name: new FormControl('', [Validators.required]),
    recipientGroupId: new FormControl('', [Validators.required]),
    approversGroupId: new FormControl('', [Validators.required]),
    companyId: new FormControl('', [Validators.required]),
    initialQueryDate: new FormControl(null, [Validators.required]),
  });
}
