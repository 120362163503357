import { createFeatureSelector, createSelector } from '@ngrx/store';
import { UI_FEATURE_KEY, UIState } from './ui.reducer';

export const selectUI = createFeatureSelector<UIState>(UI_FEATURE_KEY);

export const selectedPart = () =>
  createSelector(selectUI, (state: UIState) => state.selectedPart);

export const selectSingleItemsByProductionOrder = (productionOrderId: string) =>
  createSelector(
    selectUI,
    (state: UIState) => state.singleItemsByProductionOrder[productionOrderId],
  );

export const selectSingleItemsByProductionOrderLoading = () =>
  createSelector(
    selectUI,
    (state: UIState) => state.singleItemsByProductionOrderLoading,
  );

export const selectNotifications = () =>
  createSelector(selectUI, (state: UIState) => state?.notifications);

export const selectAllCompaniesRelatedToArticles = () =>
  createSelector(
    selectUI,
    (state: UIState) => state?.allCompaniesRelatedToArticles || [],
  );
