import {
  AfterViewInit,
  Component,
  DestroyRef,
  QueryList,
  ViewChildren,
  inject,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Router } from '@angular/router';
import {
  fetchSingleItemsByProductionOrder,
  selectSingleItemsByProductionOrder,
  selectSingleItemsByProductionOrderLoading,
  selectedPart,
} from '@core-app/state/ui';
import { ComponentDataState } from '@enums';
import {
  TableColumn,
  TableComponent,
} from '@frontend-workspace/shared/src/lib/components/table/table.component';
import { ModalKey } from '@frontend-workspace/shared/src/lib/types/modal-key';
import { PartsSummaryDTO } from '@interfaces';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

@Component({
  selector: 'fip-part-selector',
  templateUrl: './part-selector.modal.html',
  styleUrls: ['./part-selector.modal.scss'],
})
export class PartSelectorModalComponent implements AfterViewInit {
  readonly modalKey: ModalKey = 'part-selector';

  private readonly _router = inject(Router);
  private readonly _destroyRef = inject(DestroyRef);
  private readonly _store = inject(Store);

  @ViewChildren(TableComponent) tables: QueryList<
    TableComponent<PartsSummaryDTO>
  >;

  partId: string;
  productionOrderId = '';
  tableData: PartsSummaryDTO[] = [];
  dataState: ComponentDataState = ComponentDataState.Loading;
  ComponentDataState = ComponentDataState;

  loading$: Observable<boolean> = this._store.select(
    selectSingleItemsByProductionOrderLoading(),
  );

  readonly columns: TableColumn<PartsSummaryDTO>[] = [
    {
      index: 'partId',
      label: 'Part ID',
      key: 'externalSingleItemId',
    },
    {
      index: 'actions',
      label: 'Details',
      key: 'actions',
    },
  ];

  readonly displayedColumns = ['partId', 'actions'];

  ngAfterViewInit() {
    this.tables.changes.subscribe((tables) => {
      const currentPartIdIndex = this.tableData.findIndex(
        (item) => item.externalSingleItemId === this.partId,
      );

      tables.first?.goToItemIndex(currentPartIdIndex);
    });

    setTimeout(() => {
      this.fetchItems();
    }, 0);
  }

  fetchItems() {
    this._store
      .select(selectedPart())
      .pipe(takeUntilDestroyed(this._destroyRef))
      .subscribe((partData) => {
        this.partId = partData.serialNumber;

        if (
          Object.keys(partData).length > 0 &&
          partData?.selectedProductionOrderId !== this.productionOrderId
        ) {
          this.productionOrderId = partData.selectedProductionOrderId;

          this._store
            .select(selectSingleItemsByProductionOrder(this.productionOrderId))
            .subscribe((items) => {
              if (!items) {
                this._store.dispatch(
                  fetchSingleItemsByProductionOrder({
                    productionOrderId: this.productionOrderId,
                  }),
                );
                return;
              }

              if (items.length === 0) {
                this.dataState = ComponentDataState.NoData;
              } else {
                this.dataState = ComponentDataState.HasData;
                this.tableData = items as PartsSummaryDTO[];

                this.tableData = this.tableData.map((item) => {
                  const actions: {
                    label: string;
                    icon: string;
                    key: string;
                    action: (s: PartsSummaryDTO) => void;
                  }[] = [
                    {
                      label: 'Part Overview',
                      icon: 'arrow_forward',
                      key: 'part_overview',
                      action: (e: PartsSummaryDTO) => {
                        this._router.navigate(['/parts/' + e.singleItemId]);
                      },
                    },
                  ];

                  const newItem = { ...item };

                  newItem.externalSingleItemId =
                    newItem.externalSingleItemId || newItem.singleItemId;

                  return {
                    ...newItem,
                    actions,
                  };
                });
              }
            });
        }
      });
  }

  navigateToPart(part: PartsSummaryDTO) {
    this.partId = part.externalSingleItemId || part.singleItemId;
    this._router.navigateByUrl(`/parts/${part.singleItemId}`);
  }
}
