import { Component, DestroyRef, inject } from '@angular/core';
import {
  selectSingleItems,
  selectSingleItemsLoading,
} from '@core-app/state/production';
import { TableColumn } from '@frontend-workspace/shared/src/lib/components/table/table.component';
import { Store } from '@ngrx/store';
import { SharedModule } from '@frontend-workspace/shared/src';
import { SingleItem } from '@frontend-workspace/shared/src/lib/interfaces';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'frontend-workspace-parts-modal',
  standalone: true,
  imports: [SharedModule, CommonModule],
  templateUrl: './parts-modal.component.html',
  styleUrl: './parts-modal.component.css',
})
export class PartsModalComponent {
  private readonly _store = inject(Store);
  destroyRef = inject(DestroyRef);
  singleItems$ = this._store.select(selectSingleItems());
  singleItemsLoading$ = this._store.select(selectSingleItemsLoading());
  cols: TableColumn<SingleItem>[] = [
    {
      index: 'articleId',
      label: 'Article ID',
      key: 'articleId',
    },
    {
      index: 'externalSingleItemId',
      label: 'External Single Item ID',
      key: 'externalSingleItemId',
    },
    {
      index: 'singleItemId',
      label: 'Single Item ID',
      key: 'singleItemId',
    },
    {
      index: 'batchId',
      label: 'Batch',
      key: 'batchId',
    },
  ];

  colsDisplay = [
    'singleItemId',
    'articleId',
    'batchId',
    'externalSingleItemId',
  ];
}
