import { featureConfig, User } from '@interfaces';
import { createAction, props } from '@ngrx/store';

export const initUser = createAction('[User Page] Init');

export const setUserFeatureFlags = createAction(
  '[User/API] Set User Feature Flags',
  props<{ featureFlags: featureConfig | null }>(),
);

export const getUserFeatureFlags = createAction(
  '[User/API] Get User Feature Flags',
  props<{ id: string }>(),
);

export const setUser = createAction(
  '[User/API] Set User',
  props<{ user: User | null }>(),
);
