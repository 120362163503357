import { Component, inject } from '@angular/core';
import { AuthService } from '@core-app/services/auth.service';
import { showModal } from '@frontend-workspace/shared/src/lib/+state/app.actions';
import { Store } from '@ngrx/store';
import { FeatureFlagsService } from './services/feature-flags.service';
import { ModalService } from './services/modal.service';

@Component({
  selector: 'frontend-workspace-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  private readonly _modalService = inject(ModalService);
  private readonly _store = inject(Store);

  readonly _featureFlagService = inject(FeatureFlagsService);

  constructor(private readonly _authService: AuthService) {
    this._authService.saveAuthenticatedUser();
    this._modalService.init();
  }

  openSupportDialog() {
    this._store.dispatch(
      showModal({
        modalKey: 'support',
        data: { title: 'asdsad' },
        width: '900px',
      }),
    );
  }
}
