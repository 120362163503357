/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { Injectable, inject } from '@angular/core';
import { Article, Company, LineChartDataPoints } from '@interfaces';
import * as dayjs from 'dayjs';
import { of } from 'rxjs';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root',
})
export class OEEService {
  private readonly _apiPath = 'oee';
  private readonly _api = inject(ApiService);

  getOEEMetrics({
    articleId,
    companyCode,
    from,
    to,
  }: {
    articleId: Article['articleId'];
    companyCode: Company['code'];
    from: dayjs.Dayjs | null | undefined;
    to: dayjs.Dayjs | null | undefined;
  }) {
    return this._api.get<{
      target: number;
      availability: number;
      performance: number;
      quality: number;
    }>(
      `${this._apiPath}/summary/?articleNum=${articleId}&factory=${companyCode}&from=${from}&to=${to}`,
    );
  }

  getAvailabilityData({
    articleId,
    companyCode,
    from,
    to,
  }: {
    articleId: Article['articleId'];
    companyCode: Company['code'];
    from: dayjs.Dayjs | null | undefined;
    to: dayjs.Dayjs | null | undefined;
  }) {
    const chartData: Array<LineChartDataPoints> = [{ x: [], y: [] }];

    return this._api.get(
      `${this._apiPath}/availability/?articleNum=${articleId}&factory=${companyCode}&from=${from}&to=${to}`,
      {},
    );
  }

  getPerformanceData({
    articleId,
    companyCode,
    from,
    to,
  }: {
    articleId: Article['articleId'];
    companyCode: Company['code'];
    from: dayjs.Dayjs | null | undefined;
    to: dayjs.Dayjs | null | undefined;
  }) {
    const chartData: [{ x: any[]; y: any[] }] = [
      {
        x: [1800, 1620, 848],
        y: [
          'Robot<br>Programming',
          'Upgrading<br> to AGV',
          'Material<br>Handling Time',
        ],
      },
    ];

    const tableData = [
      {
        title: 'Issue 1',
        rootCause: 'Issue 1 rootCause',
        timestamp: '2021-08-01',
        lineNum: '1',
        stationNum: '1',
        downTime: 1,
        downTimeCategory: 'Category 1',
        eightDReportId: '123',
        correctiveAction: 'Corrective action 1',
      },
      {
        title: 'Issue 2',
        rootCause: 'Issue 2 description',
        timestamp: '2021-08-02',
        lineNum: '2',
        stationNum: '2',
        downTime: 2,
        downTimeCategory: 'Category 2',
        eightDReportId: '124',
        correctiveAction: 'Corrective action 2',
      },
    ];

    return of({
      data: { chartData, tableData },
    });

    // return this._api.get<{
    //   availability: number;
    //   performance: number;
    //   quality: number;
    // }>(`${this._apiPath}/${articleId}/performance-data/${batchId}`, {
    //   params: {
    //     from: from?.toISOString(),
    //     to: to?.toISOString(),
    //   },
    // });
  }

  getQualityData({
    articleId,
    companyCode,
    from,
    to,
  }: {
    articleId: Article['articleId'];
    companyCode: Company['code'];
    from: dayjs.Dayjs | null | undefined;
    to: dayjs.Dayjs | null | undefined;
  }) {
    const issueData: any[] = [];

    const tableData = [
      {
        title: 'Issue 1',
        rootCause: 'Issue 1 rootCause',
        timestamp: '2021-08-01',
        lineNum: '1',
        stationNum: '1',
        downTime: 1,
        downTimeCategory: 'Category 1',
        eightDReportId: '123',
        correctiveAction: 'Corrective action 1',
      },
      {
        title: 'Issue 2',
        rootCause: 'Issue 2 description',
        timestamp: '2021-08-02',
        lineNum: '2',
        stationNum: '2',
        downTime: 2,
        downTimeCategory: 'Category 2',
        eightDReportId: '124',
        correctiveAction: 'Corrective action 2',
      },
    ];

    return of({
      data: { issueData, tableData },
    });

    // return this._api.get<{
    //   availability: number;
    //   performance: number;
    //   quality: number;
    // }>(`${this._apiPath}/${articleId}/quality-data/${batchId}`, {
    //   params: {
    //     from: from?.toISOString(),
    //     to: to?.toISOString(),
    //   },
    // });
  }
}
