<div class="hidden items-center justify-center text-xs md:flex xl:text-sm">
  <button
    mat-icon-button
    color="primary"
    (click)="previous()"
    *ngIf="(loading$ | async) === false && this.currentIndex !== 0"
  >
    <mat-icon fontIcon="chevron_left"></mat-icon>
  </button>

  <div
    class="relative mx-1 flex cursor-pointer items-center"
    (click)="openPartsModal()"
  >
    {{ partId }}
    <fip-loading-spinner
      class="scale-75"
      *ngIf="loading$ | async"
    ></fip-loading-spinner>
  </div>

  <button
    mat-icon-button
    color="primary"
    (click)="next()"
    *ngIf="(loading$ | async) === false"
  >
    <mat-icon fontIcon="chevron_right"></mat-icon>
  </button>
</div>
