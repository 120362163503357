import { ModalKey } from '@frontend-workspace/shared/src/lib/types/modal-key';
import { Company, PartsSummaryDTO, PortalNotification } from '@interfaces';
import { createAction, props } from '@ngrx/store';
import { SelectedPartData } from './ui.reducer';

export const showModal = createAction(
  '[App/API] Show modal',
  props<{
    modalKey: ModalKey;
    data?: { [key: string]: unknown | undefined };
    width?: 'smallest' | 'medium' | 'large' | string;
  }>(),
);

export const selectPart = createAction(
  '[App/API] Set selected part',
  props<{ selectedPart: SelectedPartData }>(),
);

export const fetchSingleItemsByProductionOrder = createAction(
  '[Parts/API] Fetch Single Items By Production Order',
  props<{ productionOrderId: string }>(),
);

export const toggleSingleItemsByProductionOrderLoading = createAction(
  '[Parts/API] Toggle Single Items By Production Order Loading',
  props<{ loading: boolean }>(),
);

export const fetchSingleItemsByProductionOrderSuccess = createAction(
  '[Parts/API] Fetch Single Items By Production Order Success',
  props<{ items: PartsSummaryDTO[] | null; productionOrderId: string }>(),
);

export const fetchNotifications = createAction(
  '[Alerting/API] Fetch Notifications',
);

export const fetchNotificationsSuccess = createAction(
  '[Alerting/API] Fetch Notifications Success',
  props<{ result: PortalNotification[] }>(),
);

export const markNotificationAsRead = createAction(
  '[Alerting/API] Mark Notification As Read',
  props<{ id: string }>(),
);

export const markNotificationAsReadSuccess = createAction(
  '[Alerting/API] Mark Notification As Read Success',
  props<{ result: PortalNotification | null }>(),
);

export const fetchAllCompaniesRelatedToArticles = createAction(
  '[Alerting/API] Fetch All Companies Related To Articles',
);

export const fetchAllCompaniesRelatedToArticlesSuccess = createAction(
  '[Alerting/API] Fetch All Companies Related To Articles Success',
  props<{ result: Company[] }>(),
);
