import { Component, DestroyRef, inject, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { selectSingleItems } from '@core-app/state/production';
import { TableColumn } from '@frontend-workspace/shared/src/lib/components/table/table.component';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { SharedModule } from '@frontend-workspace/shared/src';
import { SingleItem } from '@frontend-workspace/shared/src/lib/interfaces';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'frontend-workspace-parts-modal',
  standalone: true,
  imports: [SharedModule, CommonModule],
  templateUrl: './parts-modal.component.html',
  styleUrl: './parts-modal.component.css',
})
export class PartsModalComponent implements OnInit {
  private readonly _store = inject(Store);
  destroyRef = inject(DestroyRef);
  singleItems$: Observable<SingleItem[] | null>;
  cols: TableColumn<SingleItem>[] = [
    {
      index: 'serialNumber',
      label: 'Serial Number',
      key: 'serialNumber',
    },
    {
      index: 'articleId',
      label: 'Article ID',
      key: 'articleId',
    },
    {
      index: 'fipArticleId',
      label: 'FIP Article ID',
      key: 'fipArticleId',
    },
    {
      index: 'description',
      label: 'Description',
      key: 'description',
    },
    {
      index: 'batch',
      label: 'Batch',
      key: 'batch',
    },
    {
      index: 'productionOrderId',
      label: 'Production Order ID',
      key: 'productionOrderId',
    },
    {
      index: 'externalArticleId',
      label: 'External Article ID',
      key: 'externalArticleId',
    },
  ];

  colsDisplay = ['articleId', 'batch', 'description', 'serialNumber'];

  ngOnInit(): void {
    this.singleItems$ = this._store
      .select(selectSingleItems())
      .pipe(takeUntilDestroyed(this.destroyRef));
  }
}
