import { Component, EventEmitter, Input, Output, inject } from '@angular/core';
import { Router } from '@angular/router';

import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { AuthService } from '@core-app/services/auth.service';
import { FeatureFlagsService } from '@core-app/services/feature-flags.service';
import { selectUser } from '@core-app/state/user';
import { User } from '@interfaces';
import { Store } from '@ngrx/store';
import { filter } from 'rxjs';

@Component({
  selector: 'fip-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent {
  @Input() showLogo?: boolean = false;
  @Input() unCollapsed = false;
  @Output() collapsed = new EventEmitter<boolean>();

  readonly featureFlags = inject(FeatureFlagsService);

  user: User | null = null;

  constructor(
    private readonly _store: Store,
    readonly router: Router,
    private readonly _authService: AuthService,
  ) {
    this._store
      .select(selectUser)
      .pipe(
        takeUntilDestroyed(),
        filter((user) => !!user?.firstName || !!user?.lastName),
      )
      .subscribe((user) => {
        this.user = user;
      });
  }

  logout(): void {
    this._authService.logout();
  }

  collapseSidebar(): void {
    this.collapsed.emit(true);
  }

  reloadPermissions(): void {
    const localStorageKeys = Object.keys(localStorage);

    localStorageKeys.forEach((item) => {
      if (item != 'serialSearch' && item != 'partSearch') {
        localStorage.removeItem(item);
      }
    });

    if (this.router.url.split('/').indexOf('dashboard') != -1) {
      location.reload();
    } else {
      this.router.navigate(['/dashboard', '']);
    }
  }
}
