export const environment = {
  isTestEnv: false,
  production: false,
  defaultLocale: 'en-US',
  aadClientId: 'ddb4fe83-358b-4b72-a9b2-9762b7b5690e',
  aadAuthority:
    'https://login.microsoftonline.com/a9b9b909-d389-4a5c-9c03-a7723fcd00e5',
  aadRedirectUri: 'https://test.traceability.fraenkische.com/',
  apiBaseUri: 'https://test.traceability.fraenkische.com/api/v1',
  azureFunctionsApiBaseUri:
    'https://func-fip-trapo-test-06.azurewebsites.net/api',
  apiProtectedResourceBase: 'https://test.traceability.fraenkische.com/api/*',
  azureFunctionProtectedResourceBase:
    'https://func-fip-trapo-test-06.azurewebsites.net/api/*',
  apiProtectedResourceScope:
    'api://ddb4fe83-358b-4b72-a9b2-9762b7b5690e/Access.All',
  appInsights: {
    instrumentationKey: 'd2e09c54-75af-482f-87fa-7ac4d0d8c492',
    connectionString:
      'InstrumentationKey=d2e09c54-75af-482f-87fa-7ac4d0d8c492;IngestionEndpoint=https://westeurope-5.in.applicationinsights.azure.com/;LiveEndpoint=https://westeurope.livediagnostics.monitor.azure.com/',
  },
  displayVersionNumber: 'v3.20.0',
  featureFlags: {
    production: {
      visibility: 'enabled',
      flags: {
        '8D Reports': 'enabled',
        oee: 'enabled',
        deliveries: 'enabled',
        orders: 'enabled',
        codeScanning: 'enabled',
      },
    },
    support: {
      visibility: 'hidden',
    },
    newFipLogo: {
      visibility: 'enabled',
    },
    alerting: {
      visibility: 'enabled',
    },
    SPC: {
      visibility: 'enabled',
      flags: {
        NEW_SPC_CHARTS: 'enabled',
        CAPABILITY_HISTOGRAM_CHART: 'enabled',
        LAST_SUBGROUPS_CHART: 'enabled',
        CAPABILITY_PLOT_CHART: 'enabled',
        PROBABILITY_CHART: 'enabled',
        HISTOGRAM_LINES: 'hidden',
      },
    },
    calibration: {
      visibility: 'disabled',
    },
  },
  validReceiversDomains: [
    'berlin-bytes.de',
    'btelligent.com',
    'fraenkische-cn.com',
    'fraenkische-ip.com',
    'fraenkische-mx.com',
    'fraenkische-cz.com',
  ],
  validApproversDomains: [
    'berlin-bytes.de',
    'btelligent.com',
    'fraenkische-cn.com',
    'fraenkische-ip.com',
    'fraenkische-mx.com',
    'fraenkische-cz.com',
  ],
};
