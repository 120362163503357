import { Injectable, inject } from '@angular/core';
import {
  ArticleService,
  EightDReportsService,
  LogisticsService,
  OEEService,
} from '@frontend-workspace/shared/src';
import { DATE_WITH_TIME_FORMAT_HUMAN_READABLE_WITHOUT_HOURS } from '@frontend-workspace/shared/src/lib/date-adapter/dayjs-date-formats';
import { formatToDate } from '@frontend-workspace/shared/src/lib/helpers';
import { Order } from '@frontend-workspace/shared/src/lib/interfaces';
import { PartsService } from '@frontend-workspace/shared/src/lib/services/parts.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { Observable, from, map, switchMap, tap } from 'rxjs';
import { productionActions } from './production.actions';

@Injectable()
export class ProductionEffects {
  private _actions$ = inject(Actions);
  private _store = inject(Store);
  private readonly _articleService = inject(ArticleService);
  private readonly _logisticsService = inject(LogisticsService);
  private readonly _partsService = inject(PartsService);

  private readonly _eightDReportsService = inject(EightDReportsService);
  private readonly _oeeService = inject(OEEService);

  getProductionStocks$ = createEffect(
    (): Observable<Action> =>
      this._actions$.pipe(
        ofType(productionActions.getProductionStocks),
        tap(() =>
          this._store.dispatch(
            productionActions.toggleProductionStocksLoading({
              loading: true,
            }),
          ),
        ),
        switchMap(({ articleId, companyCode }) =>
          from(this._articleService.getPlannedBom(articleId, companyCode)).pipe(
            map((response) => response.data),
            map((data) =>
              productionActions.setProductionStocks({
                articleId,
                data: data || [],
              }),
            ),
            tap(() =>
              this._store.dispatch(
                productionActions.toggleProductionStocksLoading({
                  loading: false,
                }),
              ),
            ),
          ),
        ),
      ),
  );

  getEightDReports$ = createEffect(
    (): Observable<Action> =>
      this._actions$.pipe(
        ofType(productionActions.getEightDReports),
        tap(() =>
          this._store.dispatch(
            productionActions.toggleEightDReportsLoading({
              loading: true,
            }),
          ),
        ),
        switchMap(
          ({
            articleNumber,
            batchId,
            fromTime,
            to,
            stationNum,
            downtimeCategory,
          }) =>
            from(
              this._eightDReportsService.getEightDReports({
                articleNum: articleNumber,
                batchId,
                from: fromTime,
                to,
                stationNum,
                downtimeCategory,
              }),
            ).pipe(
              map((response) => response.data),
              map((data) =>
                productionActions.setEightDReports({
                  data: data || [],
                }),
              ),
              tap(() =>
                this._store.dispatch(
                  productionActions.toggleEightDReportsLoading({
                    loading: false,
                  }),
                ),
              ),
            ),
        ),
      ),
  );

  getOEEMetrics$ = createEffect(
    (): Observable<Action> =>
      this._actions$.pipe(
        ofType(productionActions.getOEEMetrics),
        tap(() =>
          this._store.dispatch(
            productionActions.toggleOEEMetricsLoading({
              loading: true,
            }),
          ),
        ),
        switchMap(({ stationNum, companyCode, from: fromDate, to }) =>
          from(
            this._oeeService.getOEEMetrics({
              stationNum,
              companyCode,
              from: fromDate,
              to,
            }),
          ).pipe(
            map((response) => response.data),
            map((data) =>
              productionActions.setOEEMetrics({
                data,
              }),
            ),
            tap(() =>
              this._store.dispatch(
                productionActions.toggleOEEMetricsLoading({
                  loading: false,
                }),
              ),
            ),
          ),
        ),
      ),
  );

  getAvailabilityData$ = createEffect(
    (): Observable<Action> =>
      this._actions$.pipe(
        ofType(productionActions.getAvailabilityData),
        tap(() =>
          this._store.dispatch(
            productionActions.toggleAvailabilityDataLoading({
              loading: true,
            }),
          ),
        ),
        switchMap(({ stationNum, companyCode, from: fromDate, to }) =>
          from(
            this._oeeService.getAvailabilityData({
              stationNum,
              companyCode,
              from: fromDate,
              to,
            }),
          ).pipe(
            map((response) => response.data),
            map((data) =>
              productionActions.setAvailabilityData({
                data: data || [],
              }),
            ),
            tap(() =>
              this._store.dispatch(
                productionActions.toggleAvailabilityDataLoading({
                  loading: false,
                }),
              ),
            ),
          ),
        ),
      ),
  );

  getPerformanceData$ = createEffect(
    (): Observable<Action> =>
      this._actions$.pipe(
        ofType(productionActions.getPerformanceData),
        tap(() =>
          this._store.dispatch(
            productionActions.togglePerformanceDataLoading({
              loading: true,
            }),
          ),
        ),
        switchMap(({ stationNum, companyCode, from: fromDate, to }) =>
          from(
            this._oeeService.getPerformanceData({
              stationNum,
              companyCode,
              from: fromDate,
              to,
            }),
          ).pipe(
            map((response) => response.data),
            map((data) =>
              productionActions.setPerformanceData({
                data: data || [],
              }),
            ),
            tap(() =>
              this._store.dispatch(
                productionActions.togglePerformanceDataLoading({
                  loading: false,
                }),
              ),
            ),
          ),
        ),
      ),
  );

  getQualityData$ = createEffect(
    (): Observable<Action> =>
      this._actions$.pipe(
        ofType(productionActions.getQualityData),
        tap(() =>
          this._store.dispatch(
            productionActions.toggleQualityDataLoading({
              loading: true,
            }),
          ),
        ),
        switchMap(({ stationNum, companyCode, from: fromDate, to }) =>
          from(
            this._oeeService.getQualityData({
              stationNum,
              companyCode,
              from: fromDate,
              to,
            }),
          ).pipe(
            map((response) => response.data),
            map((data) =>
              productionActions.setQualityData({
                data: data || [],
              }),
            ),
            tap(() =>
              this._store.dispatch(
                productionActions.toggleQualityDataLoading({
                  loading: false,
                }),
              ),
            ),
          ),
        ),
      ),
  );
  getOrders$ = createEffect(
    (): Observable<Action> =>
      this._actions$.pipe(
        ofType(productionActions.getOrders),
        tap(() =>
          this._store.dispatch(
            productionActions.toggleOrdersLoading({
              loading: true,
            }),
          ),
        ),
        switchMap(({ articleId, companyCode, fromDate, toDate }) =>
          from(
            this._logisticsService.getOrders(
              articleId,
              companyCode,
              fromDate,
              toDate,
            ),
          ).pipe(
            map((response) => response.data),
            map((data) => {
              this.addReadableDataToOrders(data ?? []);
              return productionActions.setOrders({
                orders: data || [],
              });
            }),
            tap(() =>
              this._store.dispatch(
                productionActions.toggleOrdersLoading({
                  loading: false,
                }),
              ),
            ),
          ),
        ),
      ),
  );

  addReadableDataToOrders(orders: Order[]) {
    orders.forEach((order) => {
      order.creationDate =
        formatToDate(
          order.creationDate,
          DATE_WITH_TIME_FORMAT_HUMAN_READABLE_WITHOUT_HOURS,
        ) || '';
    });
  }

  getDeliveriesCore$ = createEffect(
    (): Observable<Action> =>
      this._actions$.pipe(
        ofType(productionActions.getDeliveriesCore),
        tap(() =>
          this._store.dispatch(
            productionActions.toggleDeliveriesLoading({
              loading: true,
            }),
          ),
        ),
        switchMap(({ deliveryNoteId, companyCode }) =>
          from(
            this._logisticsService.getDeliveriesCore(
              deliveryNoteId,
              companyCode,
            ),
          ).pipe(
            map((response) => response.data),
            map((data) => {
              return productionActions.setDeliveriesCore({
                deliveries: data || [],
              });
            }),
            tap(() =>
              this._store.dispatch(
                productionActions.toggleDeliveriesLoading({
                  loading: false,
                }),
              ),
            ),
          ),
        ),
      ),
  );

  getDeliveryPositions$ = createEffect(
    (): Observable<Action> =>
      this._actions$.pipe(
        ofType(productionActions.getDeliveriesPositions),
        switchMap(({ deliveryNoteId, companyCode }) =>
          from(
            this._logisticsService.getDeliveryPositions(
              deliveryNoteId,
              companyCode,
            ),
          ).pipe(
            map((response) => response.data),
            map((data) => {
              return productionActions.setDeliveriesPositions({
                positions: data || [],
              });
            }),
          ),
        ),
      ),
  );

  getCompanies$ = createEffect(
    (): Observable<Action> =>
      this._actions$.pipe(
        ofType(productionActions.getCompanies),
        switchMap(({ articleId }) =>
          from(this._logisticsService.getCompanies(articleId)).pipe(
            map((response) => response.data),
            map((data) => {
              return productionActions.setCompanies({
                companies: data || [],
              });
            }),
          ),
        ),
      ),
  );

  getSingleItems$ = createEffect(
    (): Observable<Action> =>
      this._actions$.pipe(
        ofType(productionActions.getSingleItems),
        tap(() =>
          this._store.dispatch(
            productionActions.toggleSingleItemsLoading({
              loading: true,
            }),
          ),
        ),
        switchMap(({ articleId, batchId }) =>
          from(this._partsService.getPartsByArticle(articleId, batchId)).pipe(
            map((response) => response.data),
            map((data) => {
              return productionActions.setSingleItems({
                singleItems: data || [],
              });
            }),
            tap(() =>
              this._store.dispatch(
                productionActions.toggleSingleItemsLoading({
                  loading: false,
                }),
              ),
            ),
          ),
        ),
      ),
  );

  getScannedItems$ = createEffect(
    (): Observable<Action> =>
      this._actions$.pipe(
        ofType(productionActions.getScannedItems),
        tap(() =>
          this._store.dispatch(
            productionActions.toggleScannedItemsLoading({
              loading: true,
            }),
          ),
        ),
        switchMap(() =>
          from(this._partsService.getScannedItems()).pipe(
            map((response) => response.data),
            map((data) => {
              return productionActions.setScannedItems({
                scannedItems: data || [],
              });
            }),
            tap(() =>
              this._store.dispatch(
                productionActions.toggleScannedItemsLoading({
                  loading: false,
                }),
              ),
            ),
          ),
        ),
      ),
  );
}
