import { Injectable, inject } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AlertingService } from '@frontend-workspace/shared/src/lib/services/alerting.service';
import { TOASTER_FADE_DURATION } from '@helpers';
import { HotToastService } from '@ngneat/hot-toast';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { Observable, map, switchMap, tap } from 'rxjs';
import * as AlertingActions from './alerting.actions';

@Injectable()
export class AlertingEffects {
  private readonly _actions$ = inject(Actions);
  private readonly _alertingService = inject(AlertingService);
  private readonly _store = inject(Store);
  private readonly _toaster = inject(HotToastService);
  private readonly _dialog: MatDialog = inject(MatDialog);

  fetchMonitors$ = createEffect(
    (): Observable<Action> =>
      this._actions$.pipe(
        ofType(AlertingActions.fetchMonitors),
        switchMap(({ monitorType }) => {
          return this._alertingService.getMonitorsByType(monitorType).pipe(
            map((response) => {
              const result = response.data === null ? [] : response.data;
              return AlertingActions.fetchMonitorsSuccess({
                result,
              });
            }),
          );
        }),
      ),
  );

  fetchMonitorById$ = createEffect(
    (): Observable<Action> =>
      this._actions$.pipe(
        ofType(AlertingActions.fetchMonitorById),
        switchMap(({ id }) => {
          return this._alertingService.getMonitorById(id).pipe(
            map((response) => {
              const result = response.data === null ? {} : response.data;
              return AlertingActions.fetchMonitorByIdSuccess({
                result,
              });
            }),
          );
        }),
      ),
  );

  upsertMonitor$ = createEffect(
    (): Observable<Action> =>
      this._actions$.pipe(
        ofType(AlertingActions.upsertMonitor),
        switchMap(({ monitor }) => {
          return this._alertingService.upsertMonitors(monitor).pipe(
            map(() => {
              return AlertingActions.upsertMonitorSuccess({
                monitorType: monitor.monitorType,
              });
            }),
          );
        }),
      ),
  );

  deleteMonitor$ = createEffect(
    (): Observable<Action> =>
      this._actions$.pipe(
        ofType(AlertingActions.deleteMonitor),
        switchMap(({ monitorId }) => {
          return this._alertingService.deleteMonitor(monitorId).pipe(
            map(() => {
              return AlertingActions.deleteMonitorSuccess();
            }),
          );
        }),
      ),
  );

  executeMonitor$ = createEffect(
    (): Observable<Action> =>
      this._actions$.pipe(
        ofType(AlertingActions.executeMonitor),
        switchMap(({ monitorId }) => {
          return this._alertingService.executeMonitor(monitorId).pipe(
            map(() => {
              return AlertingActions.executeMonitorSuccess();
            }),
          );
        }),
      ),
  );

  getReceiversGroup$ = createEffect(
    (): Observable<Action> =>
      this._actions$.pipe(
        ofType(AlertingActions.fetchReceiversGroup),
        tap(() => {
          this._store.dispatch(
            AlertingActions.toggleRecipientGroupLoading({ loading: true }),
          );
        }),
        switchMap(() => {
          return this._alertingService.getRecipientGroups('receivers').pipe(
            map((response) => {
              const result = response.data === null ? [] : response.data;
              result.sort((a, b) => {
                if (a.timeStamp === undefined || b.timeStamp === undefined) {
                  return 0;
                }
                return (
                  new Date(a.timeStamp).getTime() -
                  new Date(b.timeStamp).getTime()
                );
              });
              return AlertingActions.fetchRecipientGroupsSuccess({
                result: result,
              });
            }),
            tap(() => {
              this._store.dispatch(
                AlertingActions.toggleRecipientGroupLoading({ loading: false }),
              );
            }),
          );
        }),
      ),
  );

  getApproversGroup$ = createEffect(
    (): Observable<Action> =>
      this._actions$.pipe(
        ofType(AlertingActions.fetchApproversGroups),
        tap(() => {
          this._store.dispatch(
            AlertingActions.toggleApproverGroupLoading({ loading: true }),
          );
        }),
        switchMap(() => {
          return this._alertingService.getRecipientGroups('approvers').pipe(
            map((response) => {
              const result = response.data === null ? [] : response.data;
              result.sort((a, b) => {
                if (a.timeStamp === undefined || b.timeStamp === undefined) {
                  return 0;
                }
                return (
                  new Date(a.timeStamp).getTime() -
                  new Date(b.timeStamp).getTime()
                );
              });
              return AlertingActions.fetchApproversGroupsSuccess({
                result: result,
              });
            }),
            tap(() => {
              this._store.dispatch(
                AlertingActions.toggleApproverGroupLoading({ loading: false }),
              );
            }),
          );
        }),
      ),
  );

  getRecipientsFromGroup$ = createEffect(
    (): Observable<Action> =>
      this._actions$.pipe(
        ofType(AlertingActions.fetchReceiversFromGroup),
        switchMap(({ id: groupId }) => {
          return this._alertingService.getRecipientsFromGroup(groupId).pipe(
            map((response) => {
              const result = response.data === null ? [] : response.data;
              result.sort((a, b) => {
                return a.email!.localeCompare(b.email!);
              });
              return AlertingActions.fetchReceiversFromGroupSuccess({
                result: result,
              });
            }),
          );
        }),
      ),
  );

  getApproversromGroup$ = createEffect(
    (): Observable<Action> =>
      this._actions$.pipe(
        ofType(AlertingActions.fetchApproversFromGroup),
        switchMap(({ id: groupId }) => {
          return this._alertingService.getRecipientsFromGroup(groupId).pipe(
            map((response) => {
              const result = response.data === null ? [] : response.data;
              result.sort((a, b) => {
                return a.email!.localeCompare(b.email!);
              });
              return AlertingActions.fetchApproversFromGroupSuccess({
                result: result,
              });
            }),
          );
        }),
      ),
  );

  insertRecipientEmails$ = createEffect(
    (): Observable<Action> =>
      this._actions$.pipe(
        ofType(AlertingActions.upsertRecipientEmails),
        switchMap(({ dto: dto }) => {
          return this._alertingService.upsertEmailRecipients(dto).pipe(
            map(() => {
              return AlertingActions.upsertRecipientEmailsSuccess();
            }),
          );
        }),
      ),
  );

  newReceiverGroup$ = createEffect(
    (): Observable<Action> =>
      this._actions$.pipe(
        ofType(AlertingActions.createNewReceiverGroup),
        switchMap(({ dto: dto }) => {
          return this._alertingService.createNewRecipientGroup(dto).pipe(
            map((response) => {
              return AlertingActions.createNewReceiverGroupSuccess({
                result: response.data || [],
              });
            }),
          );
        }),
      ),
  );

  newAlertingGroup$ = createEffect(
    (): Observable<Action> =>
      this._actions$.pipe(
        ofType(AlertingActions.createNewApproverGroup),
        switchMap(({ dto: dto }) => {
          return this._alertingService.createNewRecipientGroup(dto).pipe(
            map((response) => {
              return AlertingActions.createNewApproverGroupSuccess({
                result: response.data || [],
              });
            }),
          );
        }),
      ),
  );

  deleteRecipientGroup$ = createEffect(
    (): Observable<Action> =>
      this._actions$.pipe(
        ofType(AlertingActions.deleteRecipientGroup),
        switchMap(({ id: groupId, groupType }) => {
          return this._alertingService.deleteRecipientGroup(groupId).pipe(
            map(() => {
              return AlertingActions.deleteRecipientGroupSuccess({ groupType });
            }),
          );
        }),
      ),
  );

  getAlerts$ = createEffect(
    (): Observable<Action> =>
      this._actions$.pipe(
        ofType(AlertingActions.fetchAlerts),
        switchMap(() => {
          return this._alertingService.getAlerts().pipe(
            map((response) => {
              const result = response.data === null ? [] : response.data;
              return AlertingActions.fetchAlertsSuccess({
                result: result,
              });
            }),
          );
        }),
      ),
  );

  getAlertById$ = createEffect(
    (): Observable<Action> =>
      this._actions$.pipe(
        ofType(AlertingActions.fetchAlertById),
        tap(() => {
          this._store.dispatch(
            AlertingActions.toggleAlertLoading({ loading: true }),
          );
        }),
        switchMap(({ id: id }) => {
          return this._alertingService.getAlertById(id).pipe(
            map((response) => {
              const result = response.data === null ? {} : response.data;

              if (response.error) {
                return AlertingActions.fetchAlertByIdFailure({
                  error: response.errorDetails?.error.error,
                });
              }

              return AlertingActions.fetchAlertByIdSuccess({
                result: result,
              });
            }),
            tap(() => {
              this._store.dispatch(
                AlertingActions.toggleAlertLoading({ loading: false }),
              );
            }),
          );
        }),
      ),
  );

  updateAlertDataById$ = createEffect(
    (): Observable<Action> =>
      this._actions$.pipe(
        ofType(AlertingActions.updateAlertDataById),
        switchMap(({ id: id }) => {
          return this._alertingService.updateAlertDataById(id).pipe(
            map((response) => {
              const result = response.data === null ? {} : response.data;
              return AlertingActions.updateAlertDataByIdSuccess({
                result: result,
              });
            }),
          );
        }),
      ),
  );

  approveAlertById$ = createEffect(
    (): Observable<Action> =>
      this._actions$.pipe(
        ofType(AlertingActions.approveAlertById),
        switchMap(({ id: id }) => {
          return this._alertingService.approveAlertById(id).pipe(
            map((response) => {
              const result = response.data === null ? {} : response.data;
              return AlertingActions.approveAlertByIdSuccess({
                result: result,
              });
            }),
          );
        }),
      ),
  );

  deleteAlertById$ = createEffect(
    (): Observable<Action> =>
      this._actions$.pipe(
        ofType(AlertingActions.deleteAlertById),
        switchMap(({ id: id }) => {
          return this._alertingService.deleteAlertById(id).pipe(
            map((response) => {
              const result = response.data === null ? '' : response.data;
              return AlertingActions.deleteAlertByIdSuccess({
                result: result,
              });
            }),
          );
        }),
      ),
  );

  deleteRecipientGroupSuccess$ = createEffect(
    (): Observable<Action> =>
      this._actions$.pipe(
        ofType(...[AlertingActions.deleteRecipientGroupSuccess]),
        tap(({ groupType }) => {
          this._dialog.closeAll();
          if (groupType === 'receivers') {
            this._store.dispatch(AlertingActions.fetchReceiversGroup());
          } else {
            this._store.dispatch(AlertingActions.fetchApproversGroups());
          }
        }),
      ),
    { dispatch: false },
  );

  showMonitorSuccessToaster$ = createEffect(
    (): Observable<Action> =>
      this._actions$.pipe(
        ofType(AlertingActions.executeMonitorSuccess),
        tap(() => {
          this._toaster.info('Monitor executed successfully', {
            id: 'monitor-executed',
            duration: TOASTER_FADE_DURATION,
          });
        }),
      ),
    { dispatch: false },
  );

  monitorUpdatedRefresh$ = createEffect(
    (): Observable<Action> =>
      this._actions$.pipe(
        ofType(AlertingActions.upsertMonitorSuccess),
        tap(({ monitorType }) => {
          this._dialog.closeAll();
          this._store.dispatch(
            AlertingActions.fetchMonitors({
              monitorType,
            }),
          );
        }),
      ),
    { dispatch: false },
  );

  sendAlertById$ = createEffect(
    (): Observable<Action> =>
      this._actions$.pipe(
        ofType(AlertingActions.sendAlertById),
        switchMap(({ id }) => {
          return this._alertingService.sendAlertById(id).pipe(
            map((response) => {
              const result = response.data === null ? {} : response.data;
              return AlertingActions.sendAlertByIdSuccess({
                result: result,
              });
            }),
          );
        }),
      ),
  );
}
