import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import {
  MAT_DIALOG_DEFAULT_OPTIONS,
  MatDialogModule,
} from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import {
  MAT_FORM_FIELD_DEFAULT_OPTIONS,
  MatFormFieldModule,
} from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTreeModule } from '@angular/material/tree';

import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { PlotlyModule } from 'angular-plotly.js';
import * as PlotlyJS from 'plotly.js-dist-min';
import { BarChartComponent } from './components/bar-chart/bar-chart.component';
import { ButtonComponent } from './components/button/button.component';
import { CustomModalComponent } from './components/custom-modal/custom-modal.component';
import { DataCardComponent } from './components/data-card/data-card.component';
import { ErrorToastComponent } from './components/error-toast/error-toast.component';
import { ErrorComponent } from './components/error/error.component';
import { FormComponent } from './components/form/form.component';
import { GenericChartComponent } from './components/generic-chart/generic-chart.component';
import { HistogramChartComponent } from './components/histogram-chart/histogram-chart.component';
import { LineChartComponent } from './components/line-chart/line-chart.component';
import { LoadingSpinnerComponent } from './components/loading-spinner/loading-spinner.component';
import { NoDataComponent } from './components/no-data/no-data.component';
import { SvgComponent } from './components/svg/svg.component';
import { TableComponent } from './components/table/table.component';
import { TabsComponent } from './components/tabs/tabs.component';
import { ToggleChartTableComponent } from './components/toggle-chart-table/toggle-chart-table.component';
import { WideCardComponent } from './components/wide-card/wide-card.component';
import { MatDayjsDateModule } from './date-adapter/dayjs-date-adapter.module';
import { DatePipe } from './pipes/date.pipe';

PlotlyModule.plotlyjs = PlotlyJS;

const COMPONENTS = [
  ButtonComponent,
  SvgComponent,
  ErrorToastComponent,
  CustomModalComponent,
  ErrorComponent,
  TabsComponent,
  TabsComponent,
  WideCardComponent,
  FormComponent,
  DataCardComponent,
  TableComponent,
  ToggleChartTableComponent,
  LineChartComponent,
  HistogramChartComponent,
  NoDataComponent,
  LoadingSpinnerComponent,
  GenericChartComponent,
  BarChartComponent,
];

const MATERIAL_MODULES = [
  MatIconModule,
  MatSortModule,
  MatTableModule,
  MatFormFieldModule,
  MatInputModule,
  MatMenuModule,
  MatTreeModule,
  MatDialogModule,
  MatTooltipModule,
  MatTabsModule,
  MatCardModule,
  MatDatepickerModule,
  MatNativeDateModule,
  MatDayjsDateModule,
  MatAutocompleteModule,
  MatPaginatorModule,
  MatExpansionModule,
  MatSlideToggleModule,
  MatButtonToggleModule,
  MatChipsModule,
  MatSelectModule,
  MatCheckboxModule,
  MatButtonModule,
  MatRadioModule,
  MatBadgeModule,
];

const PIPES = [DatePipe];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    ...MATERIAL_MODULES,
    PlotlyModule,
  ],
  declarations: [...COMPONENTS, ...PIPES],
  exports: [...COMPONENTS, ...MATERIAL_MODULES, ...PIPES],
  providers: [
    MatDatepickerModule,
    {
      provide: MAT_DIALOG_DEFAULT_OPTIONS,
      useValue: { disableClose: true, closeOnNavigation: false },
    },
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: {
        subscriptSizing: 'dynamic',
      },
    },
  ],
})
export class SharedModule {}
