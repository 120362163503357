import { DATE_WITH_TIME_FORMAT_HUMAN_READABLE_WITHOUT_HOURS } from '@frontend-workspace/shared/src/lib/date-adapter/dayjs-date-formats';
import { formatToDate } from '@helpers';
import {
  Company,
  Delivery,
  EightDReport,
  Order,
  ProductionNumber,
  SingleItem,
} from '@interfaces';
import { Action, createReducer, on } from '@ngrx/store';
import { productionActions } from './production.actions';

export const PRODUCTION_FEATURE_KEY = 'production';

export interface ProductionState {
  productionStocks: { [articleId: string]: ProductionNumber[] };
  productionStocksLoading: boolean;
  eightDReports: EightDReport[] | null;
  eightDReportsLoading: boolean;
  OEEMetrics: {
    target: number;
    availability: number;
    performance: number;
    quality: number;
  } | null;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  availabilityData: any;
  performanceData: any;
  qualityData: any;
  availabilityDataLoading?: boolean;
  performanceDataLoading?: boolean;
  qualityDataLoading?: boolean;
  orders: Order[] | null;
  ordersLoading?: boolean;
  selectedOrder: Order | null;
  deliveries: Delivery[] | null;
  deliveriesLoading?: boolean;
  companies: Company[] | null;
  singleItems: SingleItem[] | null;
}

export const initialProductionState: ProductionState = {
  productionStocks: {},
  productionStocksLoading: false,
  eightDReports: [],
  eightDReportsLoading: false,
  OEEMetrics: null,
  availabilityData: null,
  performanceData: null,
  qualityData: null,
  availabilityDataLoading: false,
  performanceDataLoading: false,
  qualityDataLoading: false,
  orders: [],
  selectedOrder: null,
  deliveries: [],
  deliveriesLoading: false,
  companies: [],
  singleItems: [],
};

const reducer = createReducer(
  initialProductionState,
  on(productionActions.toggleProductionStocksLoading, (state, { loading }) => {
    return {
      ...state,
      productionStocksLoading: loading,
    };
  }),
  on(productionActions.setProductionStocks, (state, { articleId, data }) => {
    const newProductionStocks = { ...state.productionStocks };

    if (data) {
      newProductionStocks[articleId] = data;
    }

    return {
      ...state,
      productionStocks: newProductionStocks,
    };
  }),
  on(productionActions.setEightDReports, (state, { data }) => {
    if (!data) {
      return {
        ...state,
        eightDReports: [],
      };
    }

    return {
      ...state,
      eightDReports: [...data]?.map((report) => {
        return Object.assign(
          { ...report },
          { timestamp: formatToDate(report.timestamp) || '' },
        );
      }),
    };
  }),
  on(productionActions.toggleEightDReportsLoading, (state, { loading }) => {
    return {
      ...state,
      eightDReportsLoading: loading,
    };
  }),
  on(productionActions.setOEEMetrics, (state, { data }) => {
    return {
      ...state,
      OEEMetrics: data,
    };
  }),

  on(productionActions.setAvailabilityData, (state, { data }) => {
    const newData: { x: string[]; y: number[] } = { x: [], y: [] };

    data.days.forEach((day: { day: string; availability: number }) => {
      newData.x.push(day.day);
      newData.y.push(day.availability);
    });

    return {
      ...state,
      availabilityData: Object.assign({}, data, { chartData: [newData] }),
    };
  }),

  on(productionActions.setPerformanceData, (state, { data }) => {
    return {
      ...state,
      performanceData: data,
    };
  }),

  on(productionActions.setQualityData, (state, { data }) => {
    return {
      ...state,
      qualityData: data,
    };
  }),

  on(productionActions.toggleAvailabilityDataLoading, (state, { loading }) => {
    return {
      ...state,
      availabilityDataLoading: loading,
    };
  }),

  on(productionActions.togglePerformanceDataLoading, (state, { loading }) => {
    return {
      ...state,
      performanceDataLoading: loading,
    };
  }),

  on(productionActions.toggleQualityDataLoading, (state, { loading }) => {
    return {
      ...state,
      qualityDataLoading: loading,
    };
  }),

  on(productionActions.setOrders, (state, { orders }) => {
    const trimmedOrders = orders.map((order) => {
      return {
        ...order,
        batchId: order.batchId?.trim(),
        articleId: order.articleId?.trim(),
        deliveryNoteId: order.deliveryNoteId?.trim(),
      };
    });
    return {
      ...state,
      orders: trimmedOrders,
    };
  }),

  on(productionActions.toggleOrdersLoading, (state, { loading }) => {
    return {
      ...state,
      ordersLoading: loading,
    };
  }),

  on(productionActions.selectOrder, (state, { order }) => {
    return {
      ...state,
      selectedOrder: order,
    };
  }),

  on(productionActions.setDeliveries, (state, { deliveries }) => {
    // Create a copy of the deliveries array so that we can extend its items
    const newArray = deliveries.map((delivery) => {
      return { ...delivery };
    });

    return {
      ...state,
      deliveries: addReadableDataToDeliveries(newArray),
    };
  }),

  on(productionActions.toggleDeliveriesLoading, (state, { loading }) => {
    return {
      ...state,
      deliveriesLoading: loading,
    };
  }),

  on(productionActions.setCompanies, (state, { companies }) => {
    return {
      ...state,
      companies: companies.filter((company) => company.code !== '23'),
    };
  }),

  on(productionActions.setSingleItems, (state, { singleItems }) => {
    return {
      ...state,
      singleItems: singleItems,
    };
  }),
);

export function productionReducer(
  state: ProductionState | undefined,
  action: Action,
) {
  return reducer(state, action);
}

function addReadableDataToDeliveries(deliveries: Delivery[]) {
  deliveries.forEach((delivery) => {
    switch (delivery.status) {
      case '40':
        delivery.statusText = '40: Waybill created';
        break;
      case '45':
        delivery.statusText = '45: Packages created for waybill';
        break;
      case '50':
        delivery.statusText = '50: Waybill approved';
        break;
      case '80':
        delivery.statusText = '80: Waybill closed';
        break;
      default:
        break;
    }

    delivery.deliveryNoteEntryDate =
      formatToDate(
        delivery.deliveryNoteEntryDate,
        DATE_WITH_TIME_FORMAT_HUMAN_READABLE_WITHOUT_HOURS,
      ) || '';

    delivery.creationDate =
      formatToDate(
        delivery.creationDate,
        DATE_WITH_TIME_FORMAT_HUMAN_READABLE_WITHOUT_HOURS,
      ) || '';

    delivery.plannedShippingDate =
      formatToDate(
        delivery.plannedShippingDate,
        DATE_WITH_TIME_FORMAT_HUMAN_READABLE_WITHOUT_HOURS,
      ) || '';

    // Volume and weight sometimes miss the leading zero in front of the decimal point, so we add it here
    if (delivery.volume.indexOf('.') === 0) {
      delivery.volume = `0${delivery.volume}`;
    }
    if (delivery.weight.indexOf('.') === 0) {
      delivery.weight = `0${delivery.weight}`;
    }

    delivery.volumeText = `${delivery.volume} ${delivery.volumeUnit
      .toLowerCase()
      .replace('cm3', 'cm³')
      .replace('m3', 'm³')
      .replace('dm3', 'dm³')}`;

    delivery.weightText = `${
      delivery.weight
    } ${delivery.weightUnit.toLowerCase()}`;

    delivery.address = `${delivery.city}, ${delivery.postalCode} (${delivery.countryCode})`;
  });

  return deliveries;
}
