import { DATE_WITH_TIME_FORMAT_HUMAN_READABLE_WITHOUT_HOURS } from '@frontend-workspace/shared/src/lib/date-adapter/dayjs-date-formats';
import { formatToDate } from '@helpers';
import {
  Company,
  DeliveryCore,
  DeliveryPosition,
  EightDReport,
  Order,
  ProductionNumber,
  SingleItem,
} from '@interfaces';
import { Action, createReducer, on } from '@ngrx/store';
import { productionActions } from './production.actions';

export const PRODUCTION_FEATURE_KEY = 'production';

export interface ProductionState {
  productionStocks: { [articleId: string]: ProductionNumber[] };
  productionStocksLoading: boolean;
  eightDReports: EightDReport[] | null;
  eightDReportsLoading: boolean;
  OEEMetrics: {
    target: number;
    availability: number;
    performance: number;
    quality: number;
  } | null;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  availabilityData: any;
  performanceData: any;
  qualityData: any;
  availabilityDataLoading?: boolean;
  performanceDataLoading?: boolean;
  qualityDataLoading?: boolean;
  orders: Order[] | null;
  ordersLoading?: boolean;
  selectedOrder: Order | null;
  deliveriesCore: DeliveryCore[] | null;
  deliveriesLoading?: boolean;
  companies: Company[] | null;
  singleItems: SingleItem[] | null;
  singleItemsLoading?: boolean;
  deliveryPositions: DeliveryPosition[] | null;
}

export const initialProductionState: ProductionState = {
  productionStocks: {},
  productionStocksLoading: false,
  eightDReports: [],
  eightDReportsLoading: false,
  OEEMetrics: null,
  availabilityData: null,
  performanceData: null,
  qualityData: null,
  availabilityDataLoading: false,
  performanceDataLoading: false,
  qualityDataLoading: false,
  orders: [],
  selectedOrder: null,
  deliveriesCore: [],
  deliveriesLoading: false,
  companies: [],
  singleItems: [],
  singleItemsLoading: false,
  deliveryPositions: [],
};

const reducer = createReducer(
  initialProductionState,
  on(productionActions.toggleProductionStocksLoading, (state, { loading }) => {
    return {
      ...state,
      productionStocksLoading: loading,
    };
  }),
  on(productionActions.setProductionStocks, (state, { articleId, data }) => {
    const newProductionStocks = { ...state.productionStocks };

    if (data) {
      newProductionStocks[articleId] = data;
    }

    return {
      ...state,
      productionStocks: newProductionStocks,
    };
  }),
  on(productionActions.setEightDReports, (state, { data }) => {
    if (!data) {
      return {
        ...state,
        eightDReports: [],
      };
    }

    return {
      ...state,
      eightDReports: [...data]?.map((report) => {
        return Object.assign(
          { ...report },
          { timestamp: formatToDate(report.timestamp) || '' },
        );
      }),
    };
  }),
  on(productionActions.toggleEightDReportsLoading, (state, { loading }) => {
    return {
      ...state,
      eightDReportsLoading: loading,
    };
  }),
  on(productionActions.setOEEMetrics, (state, { data }) => {
    if (!data) {
      return {
        ...state,
        OEEMetrics: null,
      };
    }

    return {
      ...state,
      OEEMetrics: data,
    };
  }),

  on(productionActions.setAvailabilityData, (state, { data }) => {
    const newData: { x: string[]; y: number[] } = { x: [], y: [] };

    if (!data) {
      return {
        ...state,
        availabilityData: null,
      };
    }

    data.days.forEach((day: { date: string; value: number }) => {
      newData.x.push(new Date(day.date).toLocaleDateString());
      newData.y.push(day.value);
    });

    return {
      ...state,
      availabilityData: Object.assign({}, data, { chartData: [newData] }),
    };
  }),

  on(productionActions.setPerformanceData, (state, { data }) => {
    const newData: { x: string[]; y: number[] } = { x: [], y: [] };

    if (!data) {
      return {
        ...state,
        performanceData: null,
      };
    }

    data.days.forEach((day: { date: string; value: number }) => {
      newData.x.push(new Date(day.date).toLocaleDateString());
      newData.y.push(day.value);
    });

    return {
      ...state,
      performanceData: Object.assign({}, data, { chartData: [newData] }),
    };
  }),

  on(productionActions.setQualityData, (state, { data }) => {
    if (!data) {
      return {
        ...state,
        qualityData: null,
      };
    }
    return {
      ...state,
      qualityData: data,
    };
  }),

  on(productionActions.toggleAvailabilityDataLoading, (state, { loading }) => {
    return {
      ...state,
      availabilityDataLoading: loading,
    };
  }),

  on(productionActions.togglePerformanceDataLoading, (state, { loading }) => {
    return {
      ...state,
      performanceDataLoading: loading,
    };
  }),

  on(productionActions.toggleQualityDataLoading, (state, { loading }) => {
    return {
      ...state,
      qualityDataLoading: loading,
    };
  }),

  on(productionActions.setOrders, (state, { orders }) => {
    const trimmedOrders = orders.map((order) => {
      return {
        ...order,
        batchId: order.batchId?.trim(),
        articleId: order.articleId?.trim(),
        deliveryNoteId: order.deliveryNoteId?.trim(),
      };
    });
    return {
      ...state,
      orders: trimmedOrders,
    };
  }),

  on(productionActions.toggleOrdersLoading, (state, { loading }) => {
    return {
      ...state,
      ordersLoading: loading,
    };
  }),

  on(productionActions.selectOrder, (state, { order }) => {
    return {
      ...state,
      selectedOrder: order,
    };
  }),

  on(productionActions.setDeliveriesCore, (state, { deliveries }) => {
    // Create a copy of the deliveries array so that we can extend its items
    const newArray = deliveries.map((delivery) => {
      return { ...delivery };
    });

    return {
      ...state,
      deliveriesCore: addReadableDataToDeliveries(newArray),
    };
  }),

  on(productionActions.toggleDeliveriesLoading, (state, { loading }) => {
    return {
      ...state,
      deliveriesLoading: loading,
    };
  }),

  on(productionActions.setCompanies, (state, { companies }) => {
    return {
      ...state,
      companies: companies.filter((company) => company.code !== '23'),
    };
  }),

  on(productionActions.setSingleItems, (state, { singleItems }) => {
    return {
      ...state,
      singleItems: singleItems,
    };
  }),
  on(productionActions.toggleSingleItemsLoading, (state, { loading }) => {
    return {
      ...state,
      singleItemsLoading: loading,
    };
  }),
  on(productionActions.setDeliveriesPositions, (state, { positions }) => {
    // Create a copy of the positions array so that we can extend its items
    const newArray = positions.map((positions) => {
      return { ...positions };
    });

    return {
      ...state,
      deliveryPositions: addReadableDataToDeliveryPositions(newArray),
    };
  }),
);

export function productionReducer(
  state: ProductionState | undefined,
  action: Action,
) {
  return reducer(state, action);
}

function addReadableDataToDeliveries(deliveries: DeliveryCore[]) {
  deliveries.forEach((delivery) => {
    delivery.city = `[${delivery.countryCode}] ${delivery.city}`;
    delivery.address = `${delivery.city}, ${delivery.postalCode} (${delivery.countryCode})`;
  });

  return deliveries;
}

function addReadableDataToDeliveryPositions(positions: DeliveryPosition[]) {
  positions.forEach((position) => {
    position.readableVolume = `${Number(position.volume).toLocaleString()} ${
      position.volumeUnit
    }`
      .toLocaleLowerCase()
      .replace('cm3', 'cm³')
      .replace('m3', 'm³')
      .replace('dm3', 'dm³');

    position.readableWeight = `${Number(
      position.weight,
    ).toLocaleString()} ${position.weightUnit?.toLowerCase()}`;

    // Volume and weight sometimes miss the leading zero in front of the decimal point, so we add it here
    if (position.readableVolume.indexOf('.') === 0) {
      position.readableVolume = `0${position.readableVolume}`;
    }
    if (position.readableWeight?.indexOf('.') === 0) {
      position.readableWeight = `0${position.readableWeight}`;
    }

    position.plannedShippingDate =
      formatToDate(
        position.plannedShippingDate,
        DATE_WITH_TIME_FORMAT_HUMAN_READABLE_WITHOUT_HOURS,
      ) || '';
  });

  return positions;
}
