import { createFeatureSelector, createSelector } from '@ngrx/store';
import { PRODUCTION_FEATURE_KEY, ProductionState } from './production.reducer';

export const selectProductionState = createFeatureSelector<ProductionState>(
  PRODUCTION_FEATURE_KEY,
);

export const selectProductionStocksLoading = () =>
  createSelector(
    selectProductionState,
    (state: ProductionState) => state.productionStocksLoading,
  );

export const selectProductionStocks = (articleId: string) =>
  createSelector(
    selectProductionState,
    (state: ProductionState) => state.productionStocks[articleId],
  );

export const selectEightDReports = () =>
  createSelector(
    selectProductionState,
    (state: ProductionState) => state.eightDReports,
  );

export const selectEightDReportsLoading = () =>
  createSelector(
    selectProductionState,
    (state: ProductionState) => state.eightDReportsLoading,
  );

export const selectOEEMetrics = () =>
  createSelector(
    selectProductionState,
    (state: ProductionState) => state.OEEMetrics,
  );

export const selectAvailabilityData = () =>
  createSelector(
    selectProductionState,
    (state: ProductionState) => state.availabilityData,
  );

export const selectPerformanceData = () =>
  createSelector(
    selectProductionState,
    (state: ProductionState) => state.performanceData,
  );

export const selectQualityData = () =>
  createSelector(
    selectProductionState,
    (state: ProductionState) => state.qualityData,
  );

export const selectAvailabilityDataLoading = () =>
  createSelector(
    selectProductionState,
    (state: ProductionState) => state.availabilityDataLoading,
  );

export const selectPerformanceDataLoading = () =>
  createSelector(
    selectProductionState,
    (state: ProductionState) => state.performanceDataLoading,
  );

export const selectQualityDataLoading = () =>
  createSelector(
    selectProductionState,
    (state: ProductionState) => state.qualityDataLoading,
  );

export const selectOrdersLoading = () =>
  createSelector(
    selectProductionState,
    (state: ProductionState) => state.ordersLoading,
  );

export const selectOrders = () =>
  createSelector(
    selectProductionState,
    (state: ProductionState) => state.orders,
  );

export const selectedOrder = () =>
  createSelector(
    selectProductionState,
    (state: ProductionState) => state.selectedOrder,
  );

export const selectDeliveries = () =>
  createSelector(
    selectProductionState,
    (state: ProductionState) => state.deliveriesCore,
  );

export const selectDeliveriesLoading = () =>
  createSelector(
    selectProductionState,
    (state: ProductionState) => state.deliveriesLoading,
  );

export const selectCompanies = () =>
  createSelector(
    selectProductionState,
    (state: ProductionState) => state.companies,
  );

export const selectSingleItems = () =>
  createSelector(
    selectProductionState,
    (state: ProductionState) => state.singleItems,
  );
export const selectSingleItemsLoading = () =>
  createSelector(
    selectProductionState,
    (state: ProductionState) => state.singleItemsLoading,
  );

export const selectDeliveryPositions = () =>
  createSelector(
    selectProductionState,
    (state: ProductionState) => state.deliveryPositions,
  );
