import {
  Component,
  EventEmitter,
  inject,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import {
  selectAllCompanies,
  selectApproversGroups,
  selectReceiversGroups,
} from '@core-app/state/alerting';
import * as AlertingActions from '@core-app/state/alerting/alerting.actions';
import { createNewFailureDuringIncomingInspectionFormGroup } from '@frontend-workspace/shared/src/lib/services/alerting.service';
import { Store } from '@ngrx/store';

@Component({
  selector: 'fip-failure-during-incoming-inspection-form',
  templateUrl: './failure-during-incoming-inspection-form.component.html',
  styleUrl: './failure-during-incoming-inspection-form.component.scss',
})
export class FailureDuringIncomingInspectionFormComponent implements OnInit {
  @Input() submitButtonText = 'Save';
  @Input() formGroup = createNewFailureDuringIncomingInspectionFormGroup();
  @Output() submitForm = new EventEmitter();

  private readonly _store = inject(Store);

  recipientGroups$ = this._store.select(selectReceiversGroups());
  approverGroups$ = this._store.select(selectApproversGroups());
  companies$ = this._store.select(selectAllCompanies());

  ngOnInit(): void {
    this._store.dispatch(AlertingActions.fetchReceiversGroup());
    this._store.dispatch(AlertingActions.fetchAllCompanies());
  }

  triggerValidation() {
    this.formGroup.markAllAsTouched();
    this.formGroup.updateValueAndValidity();
  }

  submit() {
    this.triggerValidation();

    if (this.formGroup.valid) {
      this.submitForm.emit();
    }
  }
}
