import { CommonModule } from '@angular/common';
import { Component, Inject, inject, Input } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ModalService } from '@core-app/services/modal.service';
import { SharedModule } from '@frontend-workspace/shared/src';

@Component({
  selector: 'frontend-workspace-confirm-modal',
  standalone: true,
  imports: [SharedModule, CommonModule],
  templateUrl: './confirm-modal.component.html',
  styleUrl: './confirm-modal.component.css',
})
export class ConfirmModalComponent {
  @Input() promptText = 'Are you sure?';

  public readonly modalService = inject(ModalService);

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      [key: string]: unknown;
    },
  ) {}
}
