<div class="m-2 w-80 text-center">
  <table class="table-auto">
    <tbody>
      <tr>
        <td>ArticleId:</td>
        <td>{{ selectedOrder?.articleId }}</td>
      </tr>
      <tr>
        <td>Creation Date:</td>
        <td>{{ selectedOrder?.creationDate }}</td>
      </tr>
      <tr>
        <td>Batch Id:</td>
        <td>{{ selectedOrder?.batchId }}</td>
      </tr>
      <tr>
        <td>Transport Order Nr:</td>
        <td>{{ selectedOrder?.transportOrderNr }}</td>
      </tr>
      <tr>
        <td>Transport Requirement Nr:</td>
        <td>{{ selectedOrder?.transportRequirementNr }}</td>
      </tr>
      <tr>
        <td>Delivery Note Id:</td>
        <td>{{ selectedOrder?.deliveryNoteId }}</td>
      </tr>
      <tr>
        <td>Company Code:</td>
        <td>{{ selectedOrder?.companyCode }}</td>
      </tr>
      <tr>
        <td>Factory Reference Id:</td>
        <td>{{ selectedOrder?.factoryReferenceId }}</td>
      </tr>
      <tr>
        <td>Number Of Storage:</td>
        <td>{{ selectedOrder?.numberOfStorage }}</td>
      </tr>
      <tr>
        <td>Movement Type:</td>
        <td>{{ selectedOrder?.movementType }}</td>
      </tr>
      <tr>
        <td>Container Number:</td>
        <td>{{ selectedOrder?.containerNumber }}</td>
      </tr>
      <tr>
        <td>Storage Unit:</td>
        <td>{{ selectedOrder?.storageUnit }}</td>
      </tr>
      <tr>
        <td>Business Area:</td>
        <td>{{ selectedOrder?.businessArea }}</td>
      </tr>
      <tr>
        <td>Reservation Nr:</td>
        <td>{{ selectedOrder?.reservationNr }}</td>
      </tr>
    </tbody>
  </table>
</div>

<div class="m-5 flex justify-center">
  <fip-button
    label="Delivery"
    backgroundColor="black"
    textColor="white"
    classes="px-3 py-2 sm:ml-3 sm:mt-0 sm:w-auto shadow-sm text-sm"
    (clickEvent)="goToDeliveries()"
  ></fip-button>
</div>
