import { Injectable, inject } from '@angular/core';
import {
  ApiResponse,
  PartsAssemblyDTO,
  PartsLeakCheckDTO,
  PartsPositionCheckImageDTO,
  PartsSummaryDTO,
  SingleItem,
} from '@interfaces';
import { ApiService } from './api.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PartsService {
  private readonly _apiPath = 'single-items';
  private readonly _api = inject(ApiService);

  getPartById(id: string) {
    return this._api.get<PartsSummaryDTO>(`${this._apiPath}/${id}`);
  }

  getPartPositionCheckImages(id: string) {
    return this._api.get<PartsPositionCheckImageDTO[]>(
      `${this._apiPath}/${id}/position-check-images`,
    );
  }

  getPartLeakCheck(id: string) {
    return this._api.get<PartsLeakCheckDTO[]>(
      `${this._apiPath}/${id}/leak-check`,
    );
  }

  getAssembly(id: string) {
    return this._api.get<PartsAssemblyDTO[]>(`${this._apiPath}/${id}/assembly`);
  }

  getSingleItemsByProductionOrder(id: string) {
    return this._api.get<PartsSummaryDTO[]>(
      `production-orders/${id}/single-items`,
    );
  }

  getPartsByArticle(
    articleId?: string,
    batchId?: string,
  ): Observable<ApiResponse<SingleItem[]>> {
    return this._api.get<SingleItem[]>(
      `${this._apiPath}/article/${articleId}/batch/${batchId}`,
    );
  }
}
