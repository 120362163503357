<form [formGroup]="formGroup" (submit)="submit()">
  <fip-form class="mb-2 block">
    <mat-form-field class="w-full">
      <mat-label>Name</mat-label>
      <input matInput type="string" formControlName="name" />
    </mat-form-field>
  </fip-form>
  <fip-form class="mb-2 block">
    <mat-form-field class="w-full">
      <mat-label>Recipients Group</mat-label>

      <mat-select formControlName="recipientGroupId">
        <mat-option
          *ngFor="let group of recipientGroups$ | async"
          [value]="group.rowKey"
          >{{ group.name }}</mat-option
        >
      </mat-select>
    </mat-form-field>
  </fip-form>
  <fip-form class="mb-2 block">
    <mat-form-field class="w-full">
      <mat-label>Production Id</mat-label>
      <input matInput type="string" formControlName="productionId" />
    </mat-form-field>
  </fip-form>
  <fip-form class="mb-2 block">
    <mat-form-field class="w-full">
      <mat-label>Production Target</mat-label>
      <input matInput type="number" formControlName="productionTarget" />
    </mat-form-field>
  </fip-form>
  <fip-form formType="submit" class="text-center">
    <fip-button
      (clickEvent)="submit()"
      [label]="submitButtonText"
      backgroundColor="black"
      textColor="white"
      classes="!h-14 mt-5"
    ></fip-button>
  </fip-form>
</form>
