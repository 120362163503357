import { Component, DestroyRef, OnDestroy, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import * as SearchActions from '@core-app/state/search/search.actions';
import {
  searchLoading,
  selectArticleSearchResultsByArticlePayload,
  selectSavedArticles,
} from '@core-app/state/search/search.selectors';
import { ModalKey } from '@frontend-workspace/shared/src/lib/types/modal-key';
import { DATE_FORMAT, compareDates, formatToDate } from '@helpers';
import { ArticlePayload, ArticleSearchResult } from '@interfaces';
import { Store } from '@ngrx/store';
import * as dayjs from 'dayjs';
import { filter, tap } from 'rxjs';

@Component({
  selector: 'fip-article-search-incoming-modal',
  templateUrl: './article-search-incoming.modal.html',
  styleUrls: ['./article-search-incoming.modal.scss'],
})
export class ArticleSearchIncomingInspectionsModalComponent
  implements OnDestroy
{
  private readonly _destroyRef = inject(DestroyRef);
  private readonly _store = inject(Store);
  private readonly _router = inject(Router);

  readonly modalKey: ModalKey = 'inspections_incoming-inspections';
  readonly displayedColumns = ['batch'];
  readonly columns = [
    {
      index: 'batch',
      label: 'Batch',
      key: 'batchId' as keyof ArticleSearchResult,
    },
  ];

  searchFormGroup = new FormGroup({
    articleNumber: new FormControl('', [Validators.required]),
    productionStart: new FormControl(dayjs(), [Validators.required]),
    productionEnd: new FormControl(dayjs(), [Validators.required]),
  });
  searchLoading$ = this._store.select(searchLoading(this.modalKey));
  searchResult?: { articleNumber: string; results: ArticleSearchResult[] };
  savedArticles$ = this._store.select(selectSavedArticles);

  constructor() {
    // Disable form group while loading...
    this.searchLoading$.pipe(takeUntilDestroyed()).subscribe((loading) => {
      if (loading) {
        this.searchFormGroup.disable();
      } else {
        this.searchFormGroup.enable();
      }
    });
  }

  rowClicked(row: ArticleSearchResult) {
    this._router.navigate([`batch/${row.batchId}/incoming`], {
      queryParams: {
        rootArticleId: row.articleId,
        articleId: row.articleId,
        batchIds: row.batchId,
      },
    });
  }

  submit(
    articlePayload?:
      | ArticlePayload
      | {
          articleNumber: string;
          productionFrom: string;
          productionUntil: string;
        },
  ) {
    // check if articlePayload is coming from localstorage
    // in this case, the dates are strings and need to be converted to dayjs object
    if (articlePayload) {
      articlePayload = {
        ...articlePayload,
        productionFrom:
          typeof articlePayload?.productionFrom === 'string'
            ? dayjs(articlePayload.productionFrom, DATE_FORMAT)
            : articlePayload.productionFrom,
        productionUntil:
          typeof articlePayload?.productionUntil === 'string'
            ? dayjs(articlePayload.productionUntil, DATE_FORMAT)
            : articlePayload.productionUntil,
      };

      this.searchFormGroup
        .get('articleNumber')
        ?.setValue(articlePayload.articleNumber);
      this.searchFormGroup
        .get('productionStart')
        ?.setValue(dayjs(articlePayload.productionFrom, DATE_FORMAT));
      this.searchFormGroup
        .get('productionEnd')
        ?.setValue(dayjs(articlePayload.productionUntil, DATE_FORMAT));
    }

    const param: ArticlePayload =
      articlePayload ??
      ({
        articleNumber: this.searchFormGroup.value.articleNumber,
        productionFrom: dayjs(this.searchFormGroup.value.productionStart),
        productionUntil: dayjs(this.searchFormGroup.value.productionEnd),
      } as ArticlePayload);

    if (!param || !this.searchFormGroup.valid) {
      return;
    }

    // Listen to search result from ngrx store
    this._store
      .select(selectArticleSearchResultsByArticlePayload(param))
      .pipe(
        takeUntilDestroyed(this._destroyRef),
        tap((data) => {
          if (
            data?.articleNumber !== param.articleNumber ||
            !compareDates(data.productionFrom, param.productionFrom) ||
            !compareDates(data.productionUntil, param.productionUntil)
          ) {
            this._store.dispatch(
              SearchActions.loadArticle({
                articleNumber: param.articleNumber,
                productionFrom: dayjs(param.productionFrom),
                productionUntil: dayjs(param.productionUntil),
                searchType: this.modalKey,
              }),
            );
          }
        }),
        filter((data) => !!data),
        tap((data) => {
          if (data) {
            this._store.dispatch(
              SearchActions.reorderSearchResults({
                searchType: 'savedArticlePayloads',
                payload: {
                  articleNumber: data.articleNumber,
                  productionFrom:
                    formatToDate(data.productionFrom, DATE_FORMAT) || '',
                  productionUntil:
                    formatToDate(data.productionUntil, DATE_FORMAT) || '',
                },
              }),
            );
          }
        }),
      )
      .subscribe((data) => (this.searchResult = data));
  }

  ngOnDestroy() {
    // Clear search retsult before closing modal
    this.searchResult = undefined;
    // Remove loading before closing modal
    this._store.dispatch(
      SearchActions.toggleSearchLoading({
        searchType: this.modalKey,
        loading: false,
      }),
    );
  }
}
