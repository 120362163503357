export enum MonitorType {
  NonConfirmingPartsLeavingSupplier = 'non_confirming_parts_leaving_supplier',
  ReductionShipmentSize = 'reduction_shipment_size',
  DowntimeAboveXMinutes = 'downtime_above_x_minutes',
  MasterPedigreeChange = 'master_pedigree_change',
  PeriodicInspectionOrCalibrationFailure = 'periodic_inspection_or_calibration_failure',
  MissingData = 'missing_data',
  ReductionOfOeeBelowXPercent = 'reduction_of_oee_below_x_percent',
  IncreaseInNgRateAboveXPercent = 'increase_in_ng_rate_above_x_percent',
  SpcLevelOutOfControl = 'spc_level_out_of_control',
  RawMaterialBelowXPercent = 'raw_material_below_x_percent',
  CalibrationPeriodicTestInspectionOutOfSpecification = 'calibration_periodic_test_inspection_out_of_specification',
  DailyProductionTargetMissedByXPercent = 'daily_production_target_missed_by_x_percent',
  FailureDuringIncomingInspection = 'failure_during_incoming_inspection',
}
